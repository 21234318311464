import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";

class UsePolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    functions = {

    };

    calls = {

    };

    render() {
        return (
            <>
                <div className="text-sm font-medium mx-auto max-w-5xl sm:px-6 lg:px-8 h-full min-h-full py-8">
                    <div>Use Policy</div>
                    <div>2023.02.01</div>
                    <br />

                    <h2 className="font-bold">PRECONDITIONS</h2>
                    <p>
                        All products and services provided by Adbooster or any Adbooster sales channel may be used for lawful
                        purposes only. Transmission or storage of any information, data or material in violation of any State
                        or local laws is strictly prohibited. Customer agrees to indemnify and hold harmless Adbooster from
                        any claims resulting from Customer's use of the service which damages Customer or any other parties,
                        including attorney's fees. Adbooster will not be liable for any interruptions in service or other
                        monetary loss related to enforcement of the Adbooster Master Service Agreement (MSA), including this
                        Acceptable Use Policy.
                    </p>
                    <br />

                    <h2 className="font-bold">SERVICES PROVIDED</h2>
                    <p>
                        Adbooster provides Customer with Web-based sales and marketing automation software that includes
                        e-mail, fax, voice broadcast, e-Commerce, and affiliate functionality. All services provided must be
                        used by Customer in compliance with the Adbooster Master Service Agreement.
                    </p>
                    <br />

                    <h2 className="font-bold">CUSTOMER OBLIGATIONS</h2>
                    <p>
                        Customer agrees to use Adbooster's services in a manner that is legal, appropriate, and in conformity
                        with industry standards and to respect the privacy of consumers.
                    </p>
                    <br />

                    <h2 className="font-bold">VIOLATIONS AND PENALTIES</h2>
                    <p>
                        Customers who fail to comply with the terms of the Adbooster Master Service Agreement will be subject
                        to immediate termination of service.
                    </p>
                    <br />

                    <h2 className="font-bold">RESERVATION OF RIGHTS</h2>
                    <p>
                        Adbooster reserves the right to terminate Customer's account for any violation of this Policy.
                        Adbooster reserves the following rights:
                    </p>
                    <br />

                    <h3 className="font-bold">
                        A) Questionable Practices (<span>"Inappropriate Use"</span>)
                    </h3>
                    <p>
                        Adbooster may terminate Customer's account if Customer engages in any practice that is, in Adbooster's
                        sole discretion, objectionable, unlawful, obscene, pornographic, threatening, abusive, libelous or
                        hateful, or that encourages conduct which would constitute a criminal offense, give rise to civil
                        liability, or otherwise violate any local, state, national or international law. This includes, but is
                        not limited to:
                    </p>
                        <p>Content that in any way exploits minors under 18 years of age</p>
                        <p>Viruses, worms, phishing, malware, or any other potentially harmful software</p>
                        <p>
                            Marketing to any lists of associations, memberships, voters or realtors, or any other lists whose
                            recipients did not express explicit consent to receive such marketing material
                        </p>
                        <p>
                            Products, services, or content that are often associated with abusive business practices or spam,
                            such as:

                                <p>
                                    Pornography or illicitly pornographic sexual products, including but not limited to adult
                                    magazines, video and software, escort services, dating services, or adult "swinger"
                                    promotions
                                </p>
                                <p>Illegal drugs, software, media, or other goods</p>
                                <p>
                                    Instructions on how to assemble or otherwise make bombs, or other weaponry
                                </p>
                                <p>Online and direct pharmaceutical sales</p>
                                <p>Debt collections, credit repair, and debt relief offerings</p>
                                <p>Stock picks or promotions</p>
                                <p>"Get rich quick" and other similar offers</p>
                                <p>
                                    Promoting pyramid schemes or network marketing (i.e., MLM) businesses
                                </p>
                                <p>
                                    Odds making and betting/gambling services, including but not limited to online casino games
                                    and sporting events
                                </p>
                        </p>
                    <br />

                    <h3 className="font-bold">B) Change of This Policy</h3>
                    <p>
                        Adbooster reserves the right to change the terms and conditions of this Policy, as needed. Use of
                        Adbooster's software and/or services by Customer after said changes constitutes Customer's acceptance
                        of the new Policy. Adbooster will inform Customer when significant changes are made to any policies
                        under the Adbooster Master Service Agreement by means of the Customer's e-mail, currently on file.
                    </p>
                    <br />

                    <p className="font-bold">
                        ALL CUSTOMERS AND AFFILIATES ARE EXPECTED TO AGREE TO ALL TERMS CONTAINED HEREIN. DIGITAL ACCEPTANCE
                        IS ACHIEVED WHEN CUSTOMERS OR AFFILIATES ACCESS OR IN ANY WAY USE ADBOOSTER SERVICES.
                    </p>
                    <p className="font-bold">
                        FAILURE TO AGREE AND COMPLY WILL RESULT IN IMMEDIATE TERMINATION OF SERVICES.
                    </p>
                    <br />

                    <h2 className="font-bold">HOW TO CONTACT US</h2>
                    <p>
                        If you have any questions or concerns about this Policy, or should you wish to contact us for any
                        other reason described in this Policy, you can contact us (i) by email directed to{' '}
                        <a href="mailto:legal@adbooster.ae">legal@adbooster.ae</a> or (ii) by mail addressed to Adbooster
                        LLC, PO Box 38115, Dubai, United Arab Emirates, attention Legal/Privacy Compliance.
                    </p><br/>
                    <p>
                        This Policy was last updated as of the last date set forth above. If you would like to see an earlier
                        version of this Policy, please contact us using one of the methods set forth above and specify the
                        applicable date or time period for the prior Policy version requested.
                    </p>
                    <br />

                    <p>© 2024 Adbooster LLC. Any rights not expressly granted herein are reserved by Adbooster.</p>
                    <p>Adbooster is a registered trademark of Adbooster LLC.</p>
                </div>
            </>
        )
    }
}

export default UsePolicy;
