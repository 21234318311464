import React, { Component, Fragment } from 'react';
import { ArrowDownIcon, ArrowUpIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { userRegister } from '../../services/userRegister';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            months: [],
            stats_total: null,
            stats_month: null,
            chart_option: { name: "Actions", value: "actions" },
            chart_options: {
                tooltips: {
                    mode: 'label'
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 50,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: false
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: false
                        }
                    ]
                },
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                maintainAspectRatio: false,
            },
            chart_options_new: {
                tooltips: {
                    mode: 'label'
                },
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 50,
                        bottom: 20
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: true
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: true
                        }
                    ]
                },
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                maintainAspectRatio: false,
            },
            year: [],
            dashboard_data: {},
            healthcheck: {},
            user: {}
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user ? user : {}
            });
            if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                await this.promisedSetState({
                    chart_option: { name: "Leads", value: "leads" }
                });
                this.functions.getDashboardLight();
            } else {
                this.functions.getDashboard();
            }
        },
        getDashboardLight: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.dashboard();
                await this.promisedSetState({
                    healthcheck: {},
                    dashboard_data: response,
                    months: response.data.months,
                    stats_total: {
                        clients: { name: 'Total clients', stat: response.data.total.clients, value: 'total_clients', color: 'text-purple-500' },
                        leads: { name: 'Leads', stat: response.data.total.leads, value: 'leads', color: 'text-purple-500' },
                        unqualified: { name: 'Unqualified', stat: response.data.total.unqualified, value: 'unqualified', color: 'text-purple-500' },
                        qualified: { name: 'Qualified', stat: response.data.total.qualified, value: 'qualified', color: 'text-purple-500' },
                        pre_qualified: { name: 'Pre-qualified', stat: response.data.total.pre_qualified, value: 'pre_qualified', color: 'text-purple-500' },
                        post_qualified: { name: 'Post-qualified', stat: response.data.total.post_qualified, value: 'post_qualified', color: 'text-purple-500' },
                    },
                    stats_month: {
                        clients: { name: 'Clients', stat: response.data.month.clients, value: 'clients', color: 'text-purple-500' },
                        leads: { name: 'Leads', stat: response.data.month.leads, value: 'leads', color: 'text-purple-500' },
                        unqualified: { name: 'Unqualified', stat: response.data.month.unqualified, value: 'unqualified', color: 'text-purple-500' },
                        qualified: { name: 'Qualified', stat: response.data.month.qualified, value: 'qualified', color: 'text-purple-500' },
                        pre_qualified: { name: 'Pre-qualified', stat: response.data.month.pre_qualified, value: 'pre_qualified', color: 'text-purple-500' },
                        post_qualified: { name: 'Post-qualified', stat: response.data.month.post_qualified, value: 'post_qualified', color: 'text-purple-500' },
                    },
                    year: []
                });
            } catch (error) { }
            if (this.state.dashboard_data.data) {
                this.functions.setChartLight();
            }
            await this.promisedSetState({
                loading: false
            });
        },
        getDashboard: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.dashboard();
                await this.promisedSetState({
                    healthcheck: response.data.healthcheck,
                    dashboard_data: response,
                    months: response.data.months,
                    stats_total: {
                        clients: { name: 'Total clients', stat: response.data.total.clients, value: 'total_clients', color: 'text-purple-500' },
                        actions: { name: 'Actions', stat: response.data.total.actions, value: 'actions', color: 'text-purple-500' },
                        unqualified: { name: 'Unqualified', stat: response.data.total.unqualified, value: 'unqualified', color: 'text-purple-500' },
                        pre_qualified: { name: 'Pre-qualified', stat: response.data.total.pre_qualified, value: 'pre_qualified', color: 'text-purple-500' },
                        qualified: { name: 'Qualified', stat: response.data.total.qualified, value: 'qualified', color: 'text-purple-500' },
                        post_qualified: { name: 'Post-qualified', stat: response.data.total.post_qualified, value: 'post_qualified', color: 'text-purple-500' },
                        phone_calls: { name: 'Phone calls', stat: response.data.total.phone_calls, value: 'phone_calls', color: 'text-purple-500' },
                        fb_conversations: { name: 'Conversations', stat: response.data.total.fb_conversations, value: 'fb_conversations', color: 'text-purple-500' },
                        whats_app: { name: 'WhatsApps', stat: response.data.total.whats_app, value: 'whats_app', color: 'text-purple-500' },
                        email: { name: 'Emails', stat: response.data.total.email, value: 'email', color: 'text-purple-500' }
                    },
                    stats_month: {
                        new_clients: { name: 'New clients', stat: response.data.month.new_clients, value: 'new_clients', color: 'text-purple-500' },
                        recurring_clients: { name: 'Recurring clients', stat: response.data.month.recurring_clients, value: 'recurring_clients', color: 'text-purple-500' },
                        actions: { name: 'Actions', stat: response.data.month.actions, value: 'actions', color: 'text-purple-500' },
                        unqualified: { name: 'Unqualified', stat: response.data.month.unqualified, value: 'unqualified', color: 'text-purple-500' },
                        pre_qualified: { name: 'Pre-qualified', stat: response.data.month.pre_qualified, value: 'pre_qualified', color: 'text-purple-500' },
                        qualified: { name: 'Qualified', stat: response.data.month.qualified, value: 'qualified', color: 'text-purple-500' },
                        post_qualified: { name: 'Post-qualified', stat: response.data.month.post_qualified, value: 'post_qualified', color: 'text-purple-500' },
                        phone_calls: { name: 'Phone calls', stat: response.data.month.phone_calls, value: 'phone_calls', color: 'text-purple-500' },
                        fb_conversations: { name: 'Conversations', stat: response.data.month.fb_conversations, value: 'fb_conversations', color: 'text-purple-500' },
                        whats_app: { name: 'WhatsApps', stat: response.data.month.whats_app, value: 'whats_app', color: 'text-purple-500' },
                        email: { name: 'Emails', stat: response.data.month.email, value: 'email', color: 'text-purple-500' }
                    },
                    year: []
                });
            } catch (error) { }
            if (this.state.dashboard_data.data) {
                this.functions.setChart();
            }
            await this.promisedSetState({
                loading: false
            });
        },
        setChartLight: () => {
            let data = [];
            let lower = [];
            data = [
                {
                    lineWidth: 5,
                    label: 'Post-qualified',
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.post_qualified }) : []
                },
                {
                    lineWidth: 5,
                    label: 'Qualified',
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.qualified }) : []
                },
                {
                    lineWidth: 5,
                    label: 'Pre-qualified',
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.pre_qualified }) : []
                },
                {
                    label: 'Unqualified',
                    lineWidth: 5,
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.unqualified }) : []
                },
                {
                    label: 'Leads',
                    lineWidth: 5,
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.leads }) : []
                },
                {
                    label: 'Clients',
                    lineWidth: 5,
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.3)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.clients }) : []
                }
            ].filter((item) => {
                return item.label === this.state.chart_option.name
            });
            this.setState({
                year: data,
                lower: lower
            })
        },
        setChart: () => {
            let data = [];
            let lower = [];
            if (this.state.chart_option.value === "benchmark") {
                data.push({
                    type: 'bar',
                    label: 'Qualified leads',
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.9)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.relevant }) : []
                });
                data.push({
                    type: 'line',
                    label: 'Lower benchmark',
                    radius: 0,
                    drawBorder: false,
                    borderColor: 'rgb(255,255,255)',
                    backgroundColor: 'rgb(255, 255, 255)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.lower_limit }) : []
                });
                data.push({
                    type: 'line',
                    label: 'Upper benchmark',
                    drawBorder: false,
                    radius: 0,
                    borderColor: 'rgba(255, 255, 255, 0)',
                    backgroundColor: 'rgba(169,169,169, 0.4)',
                    data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.upper_limit }) : []
                })
            } else {
                data = [
                    {
                        lineWidth: 5,
                        label: 'Post-qualified',
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.post_qualified }) : []
                    },
                    {
                        lineWidth: 5,
                        label: 'Qualified',
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.qualified }) : []
                    },
                    {
                        label: 'Pre-qualified',
                        lineWidth: 5,
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.pre_qualified }) : []
                    },
                    {
                        label: 'Unqualified',
                        lineWidth: 5,
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.unqualified }) : []
                    },
                    {
                        label: 'Actions',
                        lineWidth: 5,
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.actions }) : []
                    },
                    {
                        label: 'New clients',
                        lineWidth: 5,
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.new_clients }) : []
                    },
                    {
                        label: 'Recurring clients',
                        lineWidth: 5,
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.3)',
                        data: Array.isArray(this.state.dashboard_data.data.months) ? this.state.dashboard_data.data.months.map((item) => { return item.recurring_clients }) : []
                    }
                ].filter((item) => {
                    return item.label === this.state.chart_option.name
                });
            }
            this.setState({
                year: data,
                lower: lower
            })
        }
    };

    calls = {
        dashboard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/dashboard/enterprise";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        fromAdmin: () => {
            return this.state.user && this.state.user.from_admin;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="grid grid-cols-12 sm:row-gap-10 row-gap-6 sm:col-gap-10 h-full min-h-full sm:p-0 p-6 pt-0">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/* BASIC */}


                {/* LIGHT */}
                {
                    !this.state.loading &&
                    this.state.stats_total &&
                    !this.renders.basicUI() &&
                    (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12">
                        <div className="grid grid-cols-4 gap-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Since start</h3>
                            <div className="relative overflow-hidden border rounded-lg bg-white px-4 pt-5 pb-6 shadow sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.clients.name}</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_total.clients.color + " text-2xl font-semibold"}>{this.state.stats_total.clients.stat}</p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden border rounded-lg bg-white shadow col-span-4 md:col-span-3">
                                <div className="grid grid-cols-5 h-full">
                                    <div className="col-span-1 lg:mt-0 mt-2 border-r h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.leads.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.leads.color + " text-2xl font-semibold"}>{this.state.stats_total.leads.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.unqualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.unqualified.color + " text-2xl font-semibold"}>{this.state.stats_total.unqualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.pre_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.pre_qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.pre_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_total.post_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.post_qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.post_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* LIGHT */}
                {
                    !this.state.loading &&
                    this.state.stats_total &&
                    !this.renders.basicUI() &&
                    (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12">
                        <div className="grid grid-cols-4 gap-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Current month</h3>
                            <div className="relative overflow-hidden border rounded-lg bg-white px-4 pt-5 pb-6 shadow sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.clients.name}</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_month.clients.color + " text-2xl font-semibold"}>{this.state.stats_month.clients.stat}</p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden border rounded-lg bg-white shadow col-span-4 md:col-span-3">
                                <div className="grid grid-cols-5 h-full">
                                    <div className="col-span-1 lg:mt-0 mt-2 border-r h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.leads.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.leads.color + " text-2xl font-semibold"}>{this.state.stats_month.leads.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.unqualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.unqualified.color + " text-2xl font-semibold"}>{this.state.stats_month.unqualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.pre_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.pre_qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.pre_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                        <dt><p className="text-xxs font-medium text-gray-900">{this.state.stats_month.post_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.post_qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.post_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* LIGHT */}
                {
                    !this.state.loading &&
                    !this.renders.basicUI() &&
                    (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12 gap-4 grid grid-cols-12">
                        <h3 className="text-lg flex flex-row whitespace-no-wrap font-medium leading-6 text-gray-900 col-span-12">
                            Monthly breakdown
                            <div className="flex flex-1 overflow-auto">
                                {
                                    [
                                        { name: "Leads", value: "leads" },
                                        { name: "Unqualified", value: "unqualified" },
                                        { name: "Pre-qualified", value: "pre_qualified" },
                                        { name: "Qualified", value: "qualified" },
                                        { name: "Post-qualified", value: "post_qualified" },
                                        { name: "Clients", value: "clients" },
                                    ].map((item) => {
                                        return (
                                            <span onClick={async () => {
                                                await this.promisedSetState({
                                                    chart_option: item
                                                });
                                                this.functions.setChart();
                                                this.functions.setChartLight();
                                            }} className={(item.value === this.state.chart_option.value ? "text-purple-500" : "text-gray-300") + " ml-3 cursor-pointer"}>{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </h3>
                        {
                            this.state.chart_option.value !== "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12">
                                <div className="h-100">
                                    <Bar
                                        options={this.state.chart_option.value === "benchmark" ? this.state.chart_options : this.state.chart_options_new}
                                        data={{
                                            labels: Array.isArray(this.state.months) ? this.state.months.map((item) => { let split_date = item.name.split("-"); return [split_date[0], split_date[1]] }) : [],
                                            datasets: this.state.year
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }

                {/* LEGACY */}
                {
                    !this.state.loading &&
                    this.state.stats_total &&
                    !this.renders.basicUI() &&
                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12 sm:col-span-6">
                        <div className="grid grid-cols-4 gap-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Since start</h3>
                            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 border shadow sm:px-6 sm:pt-6 col-span-4">
                                <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.clients.name}</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_total.clients.color + " text-2xl font-semibold"}>{this.state.stats_total.clients.stat}</p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 border shadow sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.actions.name}</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_total.actions.color + " text-2xl font-semibold"}>{this.state.stats_total.actions.stat}</p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white shadow border col-span-4 md:col-span-3">
                                <div className="grid grid-cols-4 h-full">
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.unqualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.unqualified.color + " text-2xl font-semibold"}>{this.state.stats_total.unqualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.pre_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.pre_qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.pre_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.post_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.post_qualified.color + " text-2xl font-semibold"}>{this.state.stats_total.post_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 shadow border sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xs font-medium text-gray-900">Initiated</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_total.actions.color + " text-2xl font-semibold"}>
                                        {this.state.stats_total.phone_calls.stat + this.state.stats_total.whats_app.stat + this.state.stats_total.email.stat + this.state.stats_total.fb_conversations.stat}
                                    </p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white shadow border col-span-4 md:col-span-3">
                                <div className="grid grid-cols-4 h-full">
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.phone_calls.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.phone_calls.color + " text-2xl font-semibold"}>{this.state.stats_total.phone_calls.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.fb_conversations.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.fb_conversations.color + " text-2xl font-semibold"}>{this.state.stats_total.fb_conversations.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.whats_app.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.whats_app.color + " text-2xl font-semibold"}>{this.state.stats_total.whats_app.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_total.email.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_total.email.color + " text-2xl font-semibold"}>{this.state.stats_total.email.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* LEGACY */}
                {
                    !this.state.loading &&
                    this.state.stats_month &&
                    !this.renders.basicUI() &&
                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12 sm:col-span-6">
                        <div className="grid grid-cols-4 gap-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Current month</h3>
                            <div className="relative overflow-hidden rounded-lg bg-white shadow border col-span-4">
                                <div className="grid grid-cols-2 h-full">
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.new_clients.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.new_clients.color + " text-2xl font-semibold"}>{this.state.stats_month.new_clients.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.recurring_clients.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.recurring_clients.color + " text-2xl font-semibold"}>{this.state.stats_month.recurring_clients.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 shadow border sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.actions.name}</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_month.actions.color + " text-2xl font-semibold"}>{this.state.stats_month.actions.stat}</p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white shadow border col-span-4 md:col-span-3">
                                <div className="grid grid-cols-4 h-full">
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.unqualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.unqualified.color + " text-2xl font-semibold"}>{this.state.stats_month.unqualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.pre_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.pre_qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.pre_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.post_qualified.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.post_qualified.color + " text-2xl font-semibold"}>{this.state.stats_month.post_qualified.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 shadow border sm:px-6 sm:pt-6 col-span-4 md:col-span-1">
                                <dt><p className="text-xs font-medium text-gray-900">Initiated</p></dt>
                                <dd className="flex items-baseline">
                                    <p className={this.state.stats_month.actions.color + " text-2xl font-semibold"}>
                                        {this.state.stats_month.phone_calls.stat + this.state.stats_month.whats_app.stat + this.state.stats_month.email.stat + this.state.stats_month.fb_conversations.stat}
                                    </p>
                                    <div className="flex flex-1"></div>
                                </dd>
                            </div>
                            <div className="relative overflow-hidden rounded-lg bg-white shadow border col-span-4 md:col-span-3">
                                <div className="grid grid-cols-4 h-full">
                                    <div className="col-span-1 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.phone_calls.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.phone_calls.color + " text-2xl font-semibold"}>{this.state.stats_month.phone_calls.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.fb_conversations.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.fb_conversations.color + " text-2xl font-semibold"}>{this.state.stats_month.fb_conversations.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.whats_app.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.whats_app.color + " text-2xl font-semibold"}>{this.state.stats_month.whats_app.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                    <div className="col-span-1 lg:mt-0 mt-2 h-full flex flex-col justify-center px-4 pt-5 pb-6 sm:px-6 sm:pt-6">
                                        <dt><p className="text-xs font-medium text-gray-900">{this.state.stats_month.email.name}</p></dt>
                                        <dd className="flex items-baseline">
                                            <p className={this.state.stats_month.email.color + " text-2xl font-semibold"}>{this.state.stats_month.email.stat}</p>
                                            <div className="flex flex-1"></div>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* LEGACY */}
                {
                    !this.state.loading &&
                    !this.renders.basicUI() &&
                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                    <div className="col-span-12 gap-4 grid grid-cols-12">
                        <h3 className="text-lg flex flex-row whitespace-no-wrap font-medium leading-6 text-gray-900 col-span-12">
                            Monthly breakdown
                            <div className="flex flex-1 overflow-auto">
                                {
                                    [
                                        { name: "Actions", value: "actions" },
                                        { name: "Unqualified", value: "unqualified" },
                                        { name: "Pre-qualified", value: "pre_qualified" },
                                        { name: "Qualified", value: "qualified" },
                                        { name: "Post-qualified", value: "post_qualified" },
                                        { name: "New clients", value: "new_clients" },
                                        { name: "Recurring clients", value: "recurring_clients" },
                                        { name: "Benchmark", value: "benchmark" },
                                        { name: "Healthcheck", value: "healthcheck" }
                                    ].map((item) => {
                                        return (
                                            <span onClick={async () => {
                                                await this.promisedSetState({
                                                    chart_option: item
                                                });
                                                this.functions.setChart();
                                            }} className={(item.value === this.state.chart_option.value ? "text-purple-500" : "text-gray-300") + " ml-3 cursor-pointer"}>{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </h3>
                        {
                            this.state.chart_option.value !== "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12">
                                <div className="h-100">
                                    <Line
                                        options={this.state.chart_option.value === "benchmark" ? this.state.chart_options : this.state.chart_options_new}
                                        data={{
                                            labels: Array.isArray(this.state.months) ? this.state.months.map((item) => { let split_date = item.name.split("-"); return [split_date[0], split_date[1]] }) : [],
                                            datasets: this.state.year
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            this.state.chart_option.value === "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12 md:col-span-3">
                                <div className="pt-4">
                                    <div className="font-medium text-sm pb-4 border-b px-4">
                                        Daily spend ({this.state.healthcheck.currency}) per listing and channel
                                    </div>
                                    <div className="h-100">
                                        <Line
                                            options={this.state.chart_options}
                                            data={{
                                                labels: this.state.healthcheck.active_vs_spend.labels,
                                                datasets: [
                                                    {
                                                        type: 'bar',
                                                        label: this.state.healthcheck.name,
                                                        drawBorder: true,
                                                        borderColor: 'rgb(69, 63, 241)',
                                                        backgroundColor: 'rgba(69, 63, 241, 0.9)',
                                                        data: this.state.healthcheck.active_vs_spend.data
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Excellent',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_vs_spend.excellent
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Good',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_vs_spend.good
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Critical',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(255,0,0)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_vs_spend.critical
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.chart_option.value === "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12 md:col-span-3">
                                <div className="pt-4">
                                    <div className="font-medium text-sm pb-4 border-b px-4">
                                        Active listings
                                    </div>
                                    <div className="h-100">
                                        <Line
                                            options={this.state.chart_options}
                                            data={{
                                                labels: this.state.healthcheck.active_listings.labels,
                                                datasets: [
                                                    {
                                                        type: 'bar',
                                                        label: this.state.healthcheck.name,
                                                        drawBorder: true,
                                                        borderColor: 'rgb(69, 63, 241)',
                                                        backgroundColor: 'rgba(69, 63, 241, 0.9)',
                                                        data: this.state.healthcheck.active_listings.data
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Excellent',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_listings.excellent
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Good',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_listings.good
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Critical',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(255,0,0)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.active_listings.critical
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.chart_option.value === "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12 md:col-span-3">
                                <div className="pt-4">
                                    <div className="font-medium text-sm pb-4 border-b px-4">
                                        Price balance (%)
                                    </div>
                                    <div className="h-100">
                                        <Line
                                            options={this.state.chart_options}
                                            data={{
                                                labels: this.state.healthcheck.price_span.labels,
                                                datasets: [
                                                    {
                                                        type: 'bar',
                                                        label: this.state.healthcheck.name,
                                                        drawBorder: true,
                                                        borderColor: 'rgb(69, 63, 241)',
                                                        backgroundColor: 'rgba(69, 63, 241, 0.9)',
                                                        data: this.state.healthcheck.price_span.data
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Excellent',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.price_span.excellent
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Good',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.price_span.good
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Critical',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(255,0,0)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.price_span.critical
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.chart_option.value === "healthcheck" &&
                            <div className="bg-white shadow border overflow-hidden rounded-lg col-span-12 md:col-span-3">
                                <div className="pt-4">
                                    <div className="font-medium text-sm pb-4 border-b px-4">
                                        Quality ratings vs learning phase
                                    </div>
                                    <div className="h-100">
                                        <Line
                                            options={this.state.chart_options}
                                            data={{
                                                labels: this.state.healthcheck.ratings.labels,
                                                datasets: [
                                                    {
                                                        type: 'bar',
                                                        label: this.state.healthcheck.name,
                                                        drawBorder: true,
                                                        borderColor: 'rgb(69, 63, 241)',
                                                        backgroundColor: 'rgba(69, 63, 241, 0.9)',
                                                        data: this.state.healthcheck.ratings.data
                                                    },
                                                    {
                                                        type: 'line',
                                                        label: 'Exit learning phase',
                                                        drawBorder: true,
                                                        borderColor: 'rgb(60,179,113)',
                                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                                        data: this.state.healthcheck.ratings.good
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="col-span-12 h-2"></div>
                }

            </div>
        )
    }
}

export default Dashboard;
