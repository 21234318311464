import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            feeds: [],
            feed: {},
            enterprise: {},
            error: ""
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            enterprise: this.props.enterprise,
            feeds: this.props.feeds
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.setState({
                feeds: nextProps.feeds,
                feed: {
                    customFilter: { id: 1, value: false, name: "Disable" },
                    customFilterInclude: { id: 0, value: true, name: "Include" },
                    customFilterEmpty: { id: 1, value: false, name: "False" },
                    enterprise: this.props.enterpriseId,
                    channel: { id: 1, name: "Facebook", value: "facebook" },
                    currency: this.props.enterpriseBaseCurrency,
                    language: this.props.enterpriseBaseLanguage,
                    dial_code: { id: 1, name: "None", value: "" },
                    double_qualify: { id: 1, name: 'Inherit', value: null },
                    version: nextProps.version,
                    type: { id: 1, name: 'Both', value: "both" },
                    custom: { id: 0, value: "disabled", name: "Disabled" },
                    property: { id: 1, name: 'On-plan & Off-plan', value: "both" },
                    size_unit: this.state.enterprise.adboosterType.value === 'boostabostad' ? { id: 2, name: "SQM", value: "sqm" } : { id: 1, name: "SQFT", value: "sqft" },
                    minPrice: 0,
                    maxPrice: 0
                },
                open: false,
            });
        }
        this.setState({
            open: nextProps.open
        })
    }

    functions = {
        createFeed: async () => {
            try {
                let response = await this.calls.createFeed({
                    name: this.state.feed.name,
                    theme: this.state.feed.theme,
                    utm: this.state.feed.utm,
                    enterprise: this.state.feed.enterprise,
                    channel: this.state.feed.channel.value,
                    currency: this.state.feed.currency.value,
                    language: this.state.feed.language.value,
                    dial_code: this.state.feed.dial_code.value,
                    double_qualify: this.state.feed.double_qualify.value,
                    type: this.state.feed.type.value,
                    property: this.state.feed.property.value,
                    minPrice: this.state.feed.minPrice,
                    maxPrice: this.state.feed.maxPrice,
                    custom: this.state.feed.custom,
                    size_unit: this.state.feed.size_unit,
                    customFilter: this.state.feed.customFilter.value,
                    customFilterKey: this.state.feed.customFilterKey,
                    customFilterValue: this.state.feed.customFilterValue,
                    customFilterInclude: this.state.feed.customFilterInclude.value,
                    customFilterEmpty: this.state.feed.customFilterEmpty.value,
                    live: this.state.feed.version.id == 1
                });
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggle();
                    if (this.props.onFeed) {
                        this.props.onFeed(response.data);
                    }
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        }
    }

    calls = {
        createFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed/create-feed";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-xl">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">

                                            <div className="py-5 px-4 bg-indigo-700 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-md font-medium text-white">Add feed</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between pt-6">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                Name extension
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.name = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.name ? this.state.feed.name : ""}
                                                                    type="text"
                                                                    name="feed-name"
                                                                    id="feed-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-utm-override" className="block text-sm font-medium text-gray-900">
                                                                Utm_feed override
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.utm = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.utm ? this.state.feed.utm : ""}
                                                                    type="text"
                                                                    name="feed-utm-override"
                                                                    id="feed-utm-override"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between pb-5">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-12">
                                                            <label htmlFor="feed-custom-filter" className="block text-sm font-medium text-gray-900">
                                                                Custom filter
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.customFilter = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.customFilter ? this.state.feed.customFilter : { id: 1, value: false, name: "Disable" }}
                                                                    options={[
                                                                        { id: 0, value: true, name: "Enable" },
                                                                        { id: 1, value: false, name: "Disable" }
                                                                    ]}
                                                                    name="feed-custom-filter"
                                                                    id="feed-custom-filter"
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-include" className="block text-sm font-medium text-gray-900">
                                                                    Include/Exclude
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.feed.customFilterInclude = option;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        selected={this.state.feed.customFilterInclude ? this.state.feed.customFilterInclude : { id: 0, value: true, name: "Include" }}
                                                                        options={[
                                                                            { id: 0, value: true, name: "Include" },
                                                                            { id: 1, value: false, name: "Exclude" }
                                                                        ]}
                                                                        name="feed-custom-filter-include"
                                                                        id="feed-custom-filter-include"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-key" className="block text-sm font-medium text-gray-900">
                                                                    Key
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.feed.customFilterKey = event.target.value;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        value={this.state.feed.customFilterKey ? this.state.feed.customFilterKey : ""}
                                                                        type="text"
                                                                        name="feed-custom-filter-key"
                                                                        id="feed-custom-filter-key"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-empty" className="block text-sm font-medium text-gray-900">
                                                                    Empty value
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.feed.customFilterEmpty = option;
                                                                            if (option.value) {
                                                                                this.state.feed.customFilterValue = "";
                                                                            }
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        selected={this.state.feed.customFilterEmpty ? this.state.feed.customFilterEmpty : { id: 1, value: false, name: "False" }}
                                                                        options={[
                                                                            { id: 0, value: true, name: "True" },
                                                                            { id: 1, value: false, name: "False" }
                                                                        ]}
                                                                        name="feed-custom-filter-empty"
                                                                        id="feed-custom-filter-empty"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3 relative">
                                                                {
                                                                    this.state.feed.customFilterEmpty && this.state.feed.customFilterEmpty.value &&
                                                                    <div className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white bg-opacity-50"></div>
                                                                }
                                                                <label htmlFor="feed-custom-filter-value" className="block text-sm font-medium text-gray-900">
                                                                    Value
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.feed.customFilterValue = event.target.value;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        value={this.state.feed.customFilterValue ? this.state.feed.customFilterValue : ""}
                                                                        type="text"
                                                                        name="feed-custom-filter-value"
                                                                        id="feed-custom-filter-value"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-theme" className="block text-sm font-medium text-gray-900">
                                                                Theme override
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.theme = option;
                                                                        this.state.feed.custom = { id: 0, value: "disabled", name: "Disabled" };
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, value: "default", name: "Fallback theme" }}
                                                                    options={[
                                                                        {
                                                                            id: 0,
                                                                            value: "default",
                                                                            name: "Fallback theme"
                                                                        },
                                                                        {
                                                                            id: 1,
                                                                            value: "property_focus",
                                                                            custom: 'customThemePropertyInFocus',
                                                                            name: "Property in focus"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: "agency_focus",
                                                                            custom: 'customThemeAgencyInFocus',
                                                                            name: "Agency in focus"
                                                                        },
                                                                        {
                                                                            id: 3,
                                                                            value: "broker_focus",
                                                                            custom: 'customThemeBrokerInFocus',
                                                                            name: "Agent in focus"
                                                                        },
                                                                        {
                                                                            id: 4,
                                                                            value: "promo_focus",
                                                                            custom: 'customThemePromoInFocus',
                                                                            name: "Promo in focus"
                                                                        },
                                                                        {
                                                                            id: 5,
                                                                            value: "basic_focus",
                                                                            custom: 'customThemeBasicInFocus',
                                                                            name: "Basic"
                                                                        }
                                                                    ]}
                                                                    name="feed-theme"
                                                                    id="feed-theme"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-custom-theme" className="block text-sm font-medium text-gray-900">
                                                                Custom
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={!(this.state.feed.theme && this.state.enterprise[this.state.feed.theme.custom] && this.state.enterprise[this.state.feed.theme.custom].value === 'enabled')}
                                                                    select={(option) => {
                                                                        this.state.feed.custom = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.custom ? this.state.feed.custom : { id: 0, value: "disabled", name: "Disabled" }}
                                                                    options={[
                                                                        {
                                                                            id: 0,
                                                                            value: "disabled",
                                                                            name: "Disabled"
                                                                        },
                                                                        {
                                                                            id: 1,
                                                                            value: "enabled",
                                                                            name: "Enabled"
                                                                        }
                                                                    ]}
                                                                    name="feed-custom-theme"
                                                                    id="feed-custom-theme"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                Channel
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.channel = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.channel}
                                                                    options={[
                                                                        { id: 1, name: 'Facebook', value: "facebook" },
                                                                        { id: 2, name: 'Google', value: "google" },
                                                                        { id: 3, name: 'Snapchat', value: "snapchat" },
                                                                        { id: 4, name: 'TikTok', value: "tiktok" }
                                                                    ]}
                                                                    name="feed-channel"
                                                                    id="feed-channel"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                Type
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.type = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.type}
                                                                    options={[
                                                                        { id: 1, name: 'Both', value: "both" },
                                                                        { id: 2, name: 'Sale only', value: "sale" },
                                                                        { id: 3, name: 'Rent only', value: "rent" },
                                                                    ]}
                                                                    name="feed-type"
                                                                    id="feed-type"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-plan" className="block text-sm font-medium text-gray-900">
                                                                Plan
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.property = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.property}
                                                                    options={[
                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                    ]}
                                                                    name="feed-plan"
                                                                    id="feed-plan"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-lang" className="block text-sm font-medium text-gray-900">
                                                                Language
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.language = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.language}
                                                                    options={[
                                                                        { id: 1, name: 'English', value: "en" },
                                                                        { id: 2, name: 'Swedish', value: "sv" },
                                                                        { id: 3, name: 'Arabic', value: "ar" },
                                                                        { id: 4, name: 'German', value: "de" },
                                                                        { id: 5, name: 'Spanish', value: "es" },
                                                                        { id: 6, name: 'French', value: "fr" },
                                                                        { id: 7, name: 'Russian', value: "ru" },
                                                                        { id: 8, name: 'Dutch', value: "nl" },
                                                                        { id: 9, name: 'Portuguese', value: "pt" },
                                                                        { id: 10, name: 'Italian', value: "it" },
                                                                    ]}
                                                                    name="feed-lang"
                                                                    id="feed-lang"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-currency" className="block text-sm font-medium text-gray-900">
                                                                Currency
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.currency = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.currency}
                                                                    options={[
                                                                        { id: 1, name: 'USD', value: "usd" },
                                                                        { id: 2, name: 'SEK', value: "sek" },
                                                                        { id: 3, name: 'AED', value: "aed" },
                                                                        { id: 4, name: 'EUR', value: "eur" },
                                                                        { id: 5, name: 'GBP', value: "gbp" },
                                                                        { id: 6, name: 'ILS', value: "ils" },
                                                                        { id: 7, name: 'AUD', value: "aud" },
                                                                        { id: 8, name: 'CHF', value: "chf" },
                                                                        { id: 9, name: 'ZAR', value: "zar" },
                                                                        { id: 10, name: 'NZD', value: "nzd" },
                                                                        { id: 11, name: 'INR', value: "inr" },
                                                                        { id: 12, name: 'CAD', value: "cad" },
                                                                        { id: 13, name: 'NOK', value: "nok" },
                                                                        { id: 14, name: 'DKK', value: "dkk" },
                                                                        { id: 15, name: 'SAR', value: "sar" },
                                                                        { id: 16, name: 'QAR', value: "qar" },
                                                                        { id: 17, name: 'ADA', value: "ada" },
                                                                        { id: 18, name: 'AFN', value: "afn" },
                                                                        { id: 19, name: 'ALL', value: "all" },
                                                                        { id: 20, name: 'AMD', value: "amd" },
                                                                        { id: 21, name: 'ANG', value: "ang" },
                                                                        { id: 22, name: 'AOA', value: "aoa" },
                                                                        { id: 23, name: 'ARB', value: "arb" },
                                                                        { id: 24, name: 'ARS', value: "ars" },
                                                                        { id: 25, name: 'AVAX', value: "avax" },
                                                                        { id: 26, name: 'AWG', value: "awg" },
                                                                        { id: 27, name: 'AZN', value: "azn" },
                                                                        { id: 28, name: 'BAM', value: "bam" },
                                                                        { id: 29, name: 'BBD', value: "bbd" },
                                                                        { id: 30, name: 'BDT', value: "bdt" },
                                                                        { id: 31, name: 'BGN', value: "bgn" },
                                                                        { id: 32, name: 'BHD', value: "bhd" },
                                                                        { id: 33, name: 'BIF', value: "bif" },
                                                                        { id: 34, name: 'BMD', value: "bmd" },
                                                                        { id: 35, name: 'BNB', value: "bnb" },
                                                                        { id: 36, name: 'BND', value: "bnd" },
                                                                        { id: 37, name: 'BOB', value: "bob" },
                                                                        { id: 38, name: 'BRL', value: "brl" },
                                                                        { id: 39, name: 'BSD', value: "bsd" },
                                                                        { id: 40, name: 'BTC', value: "btc" },
                                                                        { id: 41, name: 'BTN', value: "btn" },
                                                                        { id: 42, name: 'BWP', value: "bwp" },
                                                                        { id: 43, name: 'BYN', value: "byn" },
                                                                        { id: 44, name: 'BYR', value: "byr" },
                                                                        { id: 45, name: 'BZD', value: "bzd" },
                                                                        { id: 46, name: 'CDF', value: "cdf" },
                                                                        { id: 47, name: 'CLF', value: "clf" },
                                                                        { id: 48, name: 'CLP', value: "clp" },
                                                                        { id: 49, name: 'CNY', value: "cny" },
                                                                        { id: 50, name: 'COP', value: "cop" },
                                                                        { id: 51, name: 'CRC', value: "crc" },
                                                                        { id: 52, name: 'CUC', value: "cuc" },
                                                                        { id: 53, name: 'CUP', value: "cup" },
                                                                        { id: 54, name: 'CVE', value: "cve" },
                                                                        { id: 55, name: 'CZK', value: "czk" },
                                                                        { id: 56, name: 'DAI', value: "dai" },
                                                                        { id: 57, name: 'DJF', value: "djf" },
                                                                        { id: 58, name: 'DOP', value: "dop" },
                                                                        { id: 59, name: 'DOT', value: "dot" },
                                                                        { id: 60, name: 'DZD', value: "dzd" },
                                                                        { id: 61, name: 'EGP', value: "egp" },
                                                                        { id: 62, name: 'ERN', value: "ern" },
                                                                        { id: 63, name: 'ETB', value: "etb" },
                                                                        { id: 64, name: 'ETH', value: "eth" },
                                                                        { id: 65, name: 'FJD', value: "fjd" },
                                                                        { id: 66, name: 'FKP', value: "fkp" },
                                                                        { id: 67, name: 'GEL', value: "gel" },
                                                                        { id: 68, name: 'GGP', value: "ggp" },
                                                                        { id: 69, name: 'GHS', value: "ghs" },
                                                                        { id: 70, name: 'GIP', value: "gip" },
                                                                        { id: 71, name: 'GMD', value: "gmd" },
                                                                        { id: 72, name: 'GNF', value: "gnf" },
                                                                        { id: 73, name: 'GTQ', value: "gtq" },
                                                                        { id: 74, name: 'GYD', value: "gyd" },
                                                                        { id: 75, name: 'HKD', value: "hkd" },
                                                                        { id: 76, name: 'HNL', value: "hnl" },
                                                                        { id: 77, name: 'HRK', value: "hrk" },
                                                                        { id: 78, name: 'HTG', value: "htg" },
                                                                        { id: 79, name: 'HUF', value: "huf" },
                                                                        { id: 80, name: 'IDR', value: "idr" },
                                                                        { id: 81, name: 'IMP', value: "imp" },
                                                                        { id: 82, name: 'IQD', value: "iqd" },
                                                                        { id: 83, name: 'IRR', value: "irr" },
                                                                        { id: 84, name: 'ISK', value: "isk" },
                                                                        { id: 85, name: 'JEP', value: "jep" },
                                                                        { id: 86, name: 'JMD', value: "jmd" },
                                                                        { id: 87, name: 'JOD', value: "jod" },
                                                                        { id: 88, name: 'JPY', value: "jpy" },
                                                                        { id: 89, name: 'KES', value: "kes" },
                                                                        { id: 90, name: 'KGS', value: "kgs" },
                                                                        { id: 91, name: 'KHR', value: "khr" },
                                                                        { id: 92, name: 'KMF', value: "kmf" },
                                                                        { id: 93, name: 'KPW', value: "kpw" },
                                                                        { id: 94, name: 'KRW', value: "krw" },
                                                                        { id: 95, name: 'KWD', value: "kwd" },
                                                                        { id: 96, name: 'KYD', value: "kyd" },
                                                                        { id: 97, name: 'KZT', value: "kzt" },
                                                                        { id: 98, name: 'LAK', value: "lak" },
                                                                        { id: 99, name: 'LBP', value: "lbp" },
                                                                        { id: 100, name: 'LKR', value: "lkr" },
                                                                        { id: 101, name: 'LRD', value: "lrd" },
                                                                        { id: 102, name: 'LSL', value: "lsl" },
                                                                        { id: 103, name: 'LTC', value: "ltc" },
                                                                        { id: 104, name: 'LTL', value: "ltl" },
                                                                        { id: 105, name: 'LVL', value: "lvl" },
                                                                        { id: 106, name: 'LYD', value: "lyd" },
                                                                        { id: 107, name: 'MAD', value: "mad" },
                                                                        { id: 108, name: 'MATIC', value: "matic" },
                                                                        { id: 109, name: 'MDL', value: "mdl" },
                                                                        { id: 110, name: 'MGA', value: "mga" },
                                                                        { id: 111, name: 'MKD', value: "mkd" },
                                                                        { id: 112, name: 'MMK', value: "mmk" },
                                                                        { id: 113, name: 'MNT', value: "mnt" },
                                                                        { id: 114, name: 'MOP', value: "mop" },
                                                                        { id: 115, name: 'MRO', value: "mro" },
                                                                        { id: 116, name: 'MRU', value: "mru" },
                                                                        { id: 117, name: 'MUR', value: "mur" },
                                                                        { id: 118, name: 'MVR', value: "mvr" },
                                                                        { id: 119, name: 'MWK', value: "mwk" },
                                                                        { id: 120, name: 'MXN', value: "mxn" },
                                                                        { id: 121, name: 'MYR', value: "myr" },
                                                                        { id: 122, name: 'MZN', value: "mzn" },
                                                                        { id: 123, name: 'NAD', value: "nad" },
                                                                        { id: 124, name: 'NGN', value: "ngn" },
                                                                        { id: 125, name: 'NIO', value: "nio" },
                                                                        { id: 126, name: 'OMR', value: "omr" },
                                                                        { id: 127, name: 'OP', value: "op" },
                                                                        { id: 128, name: 'PAB', value: "pab" },
                                                                        { id: 129, name: 'PEN', value: "pen" },
                                                                        { id: 130, name: 'PGK', value: "pgk" },
                                                                        { id: 131, name: 'PHP', value: "php" },
                                                                        { id: 132, name: 'PKR', value: "pkr" },
                                                                        { id: 133, name: 'PLN', value: "pln" },
                                                                        { id: 134, name: 'PYG', value: "pyg" },
                                                                        { id: 135, name: 'RON', value: "ron" },
                                                                        { id: 136, name: 'RSD', value: "rsd" },
                                                                        { id: 137, name: 'RUB', value: "rub" },
                                                                        { id: 138, name: 'RWF', value: "rwf" },
                                                                        { id: 139, name: 'SBD', value: "sbd" },
                                                                        { id: 140, name: 'SCR', value: "scr" },
                                                                        { id: 141, name: 'SDG', value: "sdg" },
                                                                        { id: 142, name: 'SGD', value: "sgd" },
                                                                        { id: 143, name: 'SHP', value: "shp" },
                                                                        { id: 144, name: 'SLL', value: "sll" },
                                                                        { id: 145, name: 'SOL', value: "sol" },
                                                                        { id: 146, name: 'SRD', value: "srd" },
                                                                        { id: 147, name: 'STD', value: "std" },
                                                                        { id: 148, name: 'STN', value: "stn" },
                                                                        { id: 149, name: 'SVC', value: "svc" },
                                                                        { id: 150, name: 'SYP', value: "syp" },
                                                                        { id: 151, name: 'SZL', value: "szl" },
                                                                        { id: 152, name: 'THB', value: "thb" },
                                                                        { id: 153, name: 'TJS', value: "tjs" },
                                                                        { id: 154, name: 'TMT', value: "tmt" },
                                                                        { id: 155, name: 'TND', value: "tnd" },
                                                                        { id: 156, name: 'TOP', value: "top" },
                                                                        { id: 157, name: 'TRY', value: "try" },
                                                                        { id: 158, name: 'TTD', value: "ttd" },
                                                                        { id: 159, name: 'TWD', value: "twd" },
                                                                        { id: 160, name: 'TZS', value: "tzs" },
                                                                        { id: 161, name: 'UAH', value: "uah" },
                                                                        { id: 162, name: 'UGX', value: "ugx" },
                                                                        { id: 163, name: 'USDC', value: "usdc" },
                                                                        { id: 164, name: 'USDT', value: "usdt" },
                                                                        { id: 165, name: 'UYU', value: "uyu" },
                                                                        { id: 166, name: 'UZS', value: "uzs" },
                                                                        { id: 167, name: 'VEF', value: "vef" },
                                                                        { id: 168, name: 'VES', value: "ves" },
                                                                        { id: 169, name: 'VND', value: "vnd" },
                                                                        { id: 170, name: 'VUV', value: "vuv" },
                                                                        { id: 171, name: 'WST', value: "wst" },
                                                                        { id: 172, name: 'XAF', value: "xaf" },
                                                                        { id: 173, name: 'XAG', value: "xag" },
                                                                        { id: 174, name: 'XAU', value: "xau" },
                                                                        { id: 175, name: 'XCD', value: "xcd" },
                                                                        { id: 176, name: 'XDR', value: "xdr" },
                                                                        { id: 177, name: 'XOF', value: "xof" },
                                                                        { id: 178, name: 'XPD', value: "xpd" },
                                                                        { id: 179, name: 'XPF', value: "xpf" },
                                                                        { id: 180, name: 'XPT', value: "xpt" },
                                                                        { id: 181, name: 'XRP', value: "xrp" },
                                                                        { id: 182, name: 'YER', value: "yer" },
                                                                        { id: 183, name: 'ZMW', value: "zmw" },
                                                                        { id: 184, name: 'ZWL', value: "zwl" }
                                                                    ]}
                                                                    name="feed-currency"
                                                                    id="feed-currency"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-dial-code" className="block text-sm font-medium text-gray-900">
                                                                Country code
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.dial_code = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.dial_code}
                                                                    options={[
                                                                        { id: 1, name: 'None', value: "" },
                                                                        { id: 2, name: 'Denmark (+45)', value: "45" },
                                                                        { id: 3, name: 'France (+33)', value: "33" },
                                                                        { id: 4, name: 'Germany (+49)', value: "49" },
                                                                        { id: 5, name: 'Ireland (+353)', value: "353" },
                                                                        { id: 6, name: 'Norway (+47)', value: "47" },
                                                                        { id: 7, name: 'Sweden (+46)', value: "46" },
                                                                        { id: 8, name: 'Switzerland (+41)', value: "41" },
                                                                        { id: 9, name: 'UK (+44)', value: "44" },
                                                                        { id: 10, name: 'Australia (+61)', value: "61" },
                                                                        { id: 11, name: 'Canada/US (+1)', value: "1" },
                                                                        { id: 12, name: 'India (+91)', value: "91" },
                                                                        { id: 13, name: 'Israel (+972)', value: "972" },
                                                                        { id: 14, name: 'New Zealand (+64)', value: "64" },
                                                                        { id: 15, name: 'Qatar (+974)', value: "974" },
                                                                        { id: 16, name: 'Saudi Arabia (+966)', value: "966" },
                                                                        { id: 17, name: 'South Africa (+27)', value: "27" },
                                                                        { id: 18, name: 'UAE (+971)', value: "971" },
                                                                        { id: 19, name: 'Spain (+34)', value: "34" },
                                                                        { id: 20, name: 'Portugal (+351)', value: "351" },
                                                                        { id: 21, name: 'Netherlands (+31)', value: "31" },
                                                                        { id: 22, name: 'Italy (+39)', value: "39" },
                                                                        { id: 23, name: 'Afghanistan (+93)', value: "93" },
                                                                        { id: 24, name: 'Albania (+355)', value: "355" },
                                                                        { id: 25, name: 'Algeria (+213)', value: "213" },
                                                                        { id: 26, name: 'American Samoa (+1684)', value: "1684" },
                                                                        { id: 27, name: 'Andorra (+376)', value: "376" },
                                                                        { id: 28, name: 'Angola (+244)', value: "244" },
                                                                        { id: 29, name: 'Antigua & Barbuda (+1268)', value: "1268" },
                                                                        { id: 30, name: 'Argentina (+54)', value: "54" },
                                                                        { id: 31, name: 'Armenia (+374)', value: "374" },
                                                                        { id: 32, name: 'Austria (+43)', value: "43" },
                                                                        { id: 33, name: 'Azerbaijan (+994)', value: "994" },
                                                                        { id: 34, name: 'Bahamas (+1242)', value: "1242" },
                                                                        { id: 35, name: 'Bahrain (+973)', value: "973" },
                                                                        { id: 36, name: 'Bangladesh (+880)', value: "880" },
                                                                        { id: 37, name: 'Barbados (+1246)', value: "1246" },
                                                                        { id: 38, name: 'Belarus (+375)', value: "375" },
                                                                        { id: 39, name: 'Belgium (+32)', value: "32" },
                                                                        { id: 40, name: 'Belize (+501)', value: "501" },
                                                                        { id: 41, name: 'Benin (+229)', value: "229" },
                                                                        { id: 42, name: 'Bhutan (+975)', value: "975" },
                                                                        { id: 43, name: 'Bolivia (+591)', value: "591" },
                                                                        { id: 44, name: 'Bosnia & Herzegovina (+387)', value: "387" },
                                                                        { id: 45, name: 'Botswana (+267)', value: "267" },
                                                                        { id: 46, name: 'Brazil (+55)', value: "55" },
                                                                        { id: 47, name: 'Brunei (+673)', value: "673" },
                                                                        { id: 48, name: 'Bulgaria (+359)', value: "359" },
                                                                        { id: 49, name: 'Burkina Faso (+226)', value: "226" },
                                                                        { id: 50, name: 'Burundi (+257)', value: "257" },
                                                                        { id: 51, name: 'Cabo Verde (+238)', value: "238" },
                                                                        { id: 52, name: 'Cambodia (+855)', value: "855" },
                                                                        { id: 53, name: 'Cameroon (+237)', value: "237" },
                                                                        { id: 54, name: 'Central African Republic (+236)', value: "236" },
                                                                        { id: 55, name: 'Chad (+235)', value: "235" },
                                                                        { id: 56, name: 'Chile (+56)', value: "56" },
                                                                        { id: 57, name: 'China (+86)', value: "86" },
                                                                        { id: 58, name: 'Colombia (+57)', value: "57" },
                                                                        { id: 59, name: 'Comoros (+269)', value: "269" },
                                                                        { id: 60, name: 'Congo (+242)', value: "242" },
                                                                        { id: 61, name: 'Costa Rica (+506)', value: "506" },
                                                                        { id: 62, name: 'Croatia (+385)', value: "385" },
                                                                        { id: 63, name: 'Cuba (+53)', value: "53" },
                                                                        { id: 64, name: 'Cyprus (+357)', value: "357" },
                                                                        { id: 65, name: 'Czech Republic (+420)', value: "420" },
                                                                        { id: 66, name: 'Djibouti (+253)', value: "253" },
                                                                        { id: 67, name: 'Dominica (+1767)', value: "1767" },
                                                                        { id: 68, name: 'Dominican Republic (+1809)', value: "1809" },
                                                                        { id: 69, name: 'Ecuador (+593)', value: "593" },
                                                                        { id: 70, name: 'Egypt (+20)', value: "20" },
                                                                        { id: 71, name: 'El Salvador (+503)', value: "503" },
                                                                        { id: 72, name: 'Equatorial Guinea (+240)', value: "240" },
                                                                        { id: 73, name: 'Eritrea (+291)', value: "291" },
                                                                        { id: 74, name: 'Estonia (+372)', value: "372" },
                                                                        { id: 75, name: 'Eswatini (+268)', value: "268" },
                                                                        { id: 76, name: 'Ethiopia (+251)', value: "251" },
                                                                        { id: 77, name: 'Fiji (+679)', value: "679" },
                                                                        { id: 78, name: 'Finland (+358)', value: "358" },
                                                                        { id: 79, name: 'Gabon (+241)', value: "241" },
                                                                        { id: 80, name: 'Gambia (+220)', value: "220" },
                                                                        { id: 81, name: 'Georgia (+995)', value: "995" },
                                                                        { id: 82, name: 'Ghana (+233)', value: "233" },
                                                                        { id: 83, name: 'Greece (+30)', value: "30" },
                                                                        { id: 84, name: 'Grenada (+1473)', value: "1473" },
                                                                        { id: 85, name: 'Guatemala (+502)', value: "502" },
                                                                        { id: 86, name: 'Guinea (+224)', value: "224" },
                                                                        { id: 87, name: 'Guinea-Bissau (+245)', value: "245" },
                                                                        { id: 88, name: 'Guyana (+592)', value: "592" },
                                                                        { id: 89, name: 'Haiti (+509)', value: "509" },
                                                                        { id: 90, name: 'Honduras (+504)', value: "504" },
                                                                        { id: 195, name: 'Hong Kong (+852)', value: "852" },
                                                                        { id: 91, name: 'Hungary (+36)', value: "36" },
                                                                        { id: 92, name: 'Iceland (+354)', value: "354" },
                                                                        { id: 93, name: 'Indonesia (+62)', value: "62" },
                                                                        { id: 94, name: 'Iran (+98)', value: "98" },
                                                                        { id: 95, name: 'Iraq (+964)', value: "964" },
                                                                        { id: 98, name: 'Jamaica (+1876)', value: "1876" },
                                                                        { id: 99, name: 'Japan (+81)', value: "81" },
                                                                        { id: 100, name: 'Jordan (+962)', value: "962" },
                                                                        { id: 101, name: 'Kazakhstan (+7)', value: "7" },
                                                                        { id: 102, name: 'Kenya (+254)', value: "254" },
                                                                        { id: 103, name: 'Kiribati (+686)', value: "686" },
                                                                        //{ id: 104, name: 'South Korea (+82)', value: "82" },
                                                                        { id: 105, name: 'Kuwait (+965)', value: "965" },
                                                                        { id: 106, name: 'Kyrgyzstan (+996)', value: "996" },
                                                                        { id: 107, name: 'Laos (+856)', value: "856" },
                                                                        { id: 108, name: 'Latvia (+371)', value: "371" },
                                                                        { id: 109, name: 'Lebanon (+961)', value: "961" },
                                                                        { id: 110, name: 'Lesotho (+266)', value: "266" },
                                                                        { id: 111, name: 'Liberia (+231)', value: "231" },
                                                                        { id: 112, name: 'Libya (+218)', value: "218" },
                                                                        { id: 113, name: 'Liechtenstein (+423)', value: "423" },
                                                                        { id: 114, name: 'Lithuania (+370)', value: "370" },
                                                                        { id: 115, name: 'Luxembourg (+352)', value: "352" },
                                                                        { id: 116, name: 'Madagascar (+261)', value: "261" },
                                                                        { id: 117, name: 'Malawi (+265)', value: "265" },
                                                                        { id: 118, name: 'Malaysia (+60)', value: "60" },
                                                                        { id: 119, name: 'Maldives (+960)', value: "960" },
                                                                        { id: 120, name: 'Mali (+223)', value: "223" },
                                                                        { id: 121, name: 'Malta (+356)', value: "356" },
                                                                        { id: 122, name: 'Marshall Islands (+692)', value: "692" },
                                                                        { id: 123, name: 'Mauritania (+222)', value: "222" },
                                                                        { id: 124, name: 'Mauritius (+230)', value: "230" },
                                                                        { id: 125, name: 'Mexico (+52)', value: "52" },
                                                                        { id: 126, name: 'Micronesia (+691)', value: "691" },
                                                                        { id: 127, name: 'Moldova (+373)', value: "373" },
                                                                        { id: 128, name: 'Monaco (+377)', value: "377" },
                                                                        { id: 129, name: 'Mongolia (+976)', value: "976" },
                                                                        { id: 130, name: 'Montenegro (+382)', value: "382" },
                                                                        { id: 131, name: 'Morocco (+212)', value: "212" },
                                                                        { id: 132, name: 'Mozambique (+258)', value: "258" },
                                                                        { id: 133, name: 'Myanmar (+95)', value: "95" },
                                                                        { id: 134, name: 'Namibia (+264)', value: "264" },
                                                                        { id: 135, name: 'Nauru (+674)', value: "674" },
                                                                        { id: 136, name: 'Nepal (+977)', value: "977" },
                                                                        { id: 137, name: 'Nicaragua (+505)', value: "505" },
                                                                        { id: 138, name: 'Niger (+227)', value: "227" },
                                                                        { id: 139, name: 'Nigeria (+234)', value: "234" },
                                                                        { id: 140, name: 'North Macedonia (+389)', value: "389" },
                                                                        { id: 142, name: 'Oman (+968)', value: "968" },
                                                                        { id: 143, name: 'Pakistan (+92)', value: "92" },
                                                                        { id: 144, name: 'Palau (+680)', value: "680" },
                                                                        { id: 145, name: 'Panama (+507)', value: "507" },
                                                                        { id: 146, name: 'Papua New Guinea (+675)', value: "675" },
                                                                        { id: 147, name: 'Paraguay (+595)', value: "595" },
                                                                        { id: 148, name: 'Peru (+51)', value: "51" },
                                                                        { id: 149, name: 'Philippines (+63)', value: "63" },
                                                                        { id: 150, name: 'Poland (+48)', value: "48" },
                                                                        { id: 151, name: 'Romania (+40)', value: "40" },
                                                                        { id: 152, name: 'Russia (+7)', value: "7" },
                                                                        { id: 153, name: 'Rwanda (+250)', value: "250" },
                                                                        { id: 154, name: 'Saint Kitts & Nevis (+1869)', value: "1869" },
                                                                        { id: 155, name: 'Saint Lucia (+1758)', value: "1758" },
                                                                        { id: 156, name: 'Saint Vincent & Grenadines (+1784)', value: "1784" },
                                                                        { id: 157, name: 'Samoa (+685)', value: "685" },
                                                                        { id: 158, name: 'San Marino (+378)', value: "378" },
                                                                        { id: 159, name: 'Sao Tome & Principe (+239)', value: "239" },
                                                                        { id: 160, name: 'Senegal (+221)', value: "221" },
                                                                        { id: 161, name: 'Serbia (+381)', value: "381" },
                                                                        { id: 162, name: 'Seychelles (+248)', value: "248" },
                                                                        { id: 163, name: 'Sierra Leone (+232)', value: "232" },
                                                                        { id: 164, name: 'Singapore (+65)', value: "65" },
                                                                        { id: 165, name: 'Slovakia (+421)', value: "421" },
                                                                        { id: 166, name: 'Slovenia (+386)', value: "386" },
                                                                        { id: 167, name: 'Solomon Islands (+677)', value: "677" },
                                                                        { id: 168, name: 'South Korea (+82)', value: "82" },
                                                                        { id: 169, name: 'South Sudan (+211)', value: "211" },
                                                                        { id: 170, name: 'Sri Lanka (+94)', value: "94" },
                                                                        { id: 171, name: 'Sudan (+249)', value: "249" },
                                                                        { id: 173, name: 'Syria (+963)', value: "963" },
                                                                        { id: 174, name: 'Taiwan (+886)', value: "886" },
                                                                        { id: 175, name: 'Tajikistan (+992)', value: "992" },
                                                                        { id: 176, name: 'Tanzania (+255)', value: "255" },
                                                                        { id: 177, name: 'Thailand (+66)', value: "66" },
                                                                        { id: 178, name: 'Togo (+228)', value: "228" },
                                                                        { id: 179, name: 'Trinidad & Tobago (+1868)', value: "1868" },
                                                                        { id: 180, name: 'Tunisia (+216)', value: "216" },
                                                                        { id: 181, name: 'Turkey (+90)', value: "90" },
                                                                        { id: 182, name: 'Turkmenistan (+993)', value: "993" },
                                                                        { id: 183, name: 'Tuvalu (+688)', value: "688" },
                                                                        { id: 184, name: 'Uganda (+256)', value: "256" },
                                                                        { id: 185, name: 'Ukraine (+380)', value: "380" },
                                                                        { id: 186, name: 'Uruguay (+598)', value: "598" },
                                                                        { id: 187, name: 'Uzbekistan (+998)', value: "998" },
                                                                        { id: 188, name: 'Vanuatu (+678)', value: "678" },
                                                                        { id: 189, name: 'Vatican City (+379)', value: "379" },
                                                                        { id: 190, name: 'Venezuela (+58)', value: "58" },
                                                                        { id: 191, name: 'Vietnam (+84)', value: "84" },
                                                                        { id: 192, name: 'Yemen (+967)', value: "967" },
                                                                        { id: 193, name: 'Zambia (+260)', value: "260" },
                                                                        { id: 194, name: 'Zimbabwe (+263)', value: "263" }
                                                                    ]}
                                                                    name="feed-dial-code"
                                                                    id="feed-dial-code"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-size-unit" className="block text-sm font-medium text-gray-900">
                                                                Size unit
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.size_unit = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.size_unit}
                                                                    options={[
                                                                        { id: 1, name: "SQFT", value: "sqft" },
                                                                        { id: 2, name: 'SQM', value: "sqm" }
                                                                    ]}
                                                                    name="feed-size-unit"
                                                                    id="feed-size-unit"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-qualify" className="block text-sm font-medium text-gray-900">
                                                                Double qualify
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.double_qualify = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.double_qualify}
                                                                    options={[
                                                                        { id: 1, name: 'Inherit', value: null },
                                                                        { id: 2, name: 'Enable', value: "enable" },
                                                                        { id: 3, name: 'Disable', value: "disable" }
                                                                    ]}
                                                                    name="feed-qualify"
                                                                    id="feed-qualify"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-min-price" className="block text-sm font-medium text-gray-900">
                                                                Min price (optional)
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.minPrice = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.minPrice}
                                                                    type="number"
                                                                    name="feed-min-price"
                                                                    id="feed-min-price"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-max-price" className="block text-sm font-medium text-gray-900">
                                                                Max price (optional)
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.maxPrice = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.maxPrice}
                                                                    type="number"
                                                                    name="feed-max-price"
                                                                    id="feed-max-price"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-version" className="block text-sm font-medium text-gray-900">
                                                                Version
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={true}
                                                                    select={(option) => {
                                                                        this.state.feed.version = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.version}
                                                                    options={[
                                                                        { id: 1, name: 'DEV', value: "dev" },
                                                                        { id: 2, name: 'LIVE', value: "live" },
                                                                    ]}
                                                                    name="feed-version"
                                                                    id="feed-version"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            {
                                                !this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => {
                                                        this.props.toggle();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading) {
                                                        this.setState({
                                                            loading: true
                                                        }, () => {
                                                            this.functions.createFeed();
                                                        })
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateFeed;
