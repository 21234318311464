import React, { Component, Fragment } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CursorClickIcon, MailOpenIcon, UsersIcon, BellIcon, PlusIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PhoneIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { versionRegister } from '../services/versionRegister';
import SingleSelection from '../components/singleSelection';
import CreateClient from '../components/createClient';
import moment from 'moment';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_enterprise: true,
            open: false,
            tab: 2,
            leads: [],
            user: {},
            version: {},
            enterprises: [],
            enterprise: { id: 0, name: "Show all" },
            status: { id: 0, name: "Show all", value: "total" },
            stats: [
                { id: 0, name: "Show all", value: "total" },
                { id: 1, name: "Cron", value: "cron" },
                { id: 2, name: "Lead", value: "lead" },
                { id: 3, name: "WhatsApp", value: "whatsapp" }
            ],
            sort: 'createdAt',
            order: 'descending',
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            this.functions.getEnterprise();
            this.functions.getLogs();
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                await this.promisedSetState({
                    enterprises: response.data,
                    loading: false
                });
                this.functions.query();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false
                })
            }
        },
        getLogs: async () => {
            try {
                await this.promisedSetState({ loading_leads: true });
                let response = await this.calls.getLogs();
                console.log(this.state.order);
                this.setState({
                    leads: response.data,
                    total: response.meta.total ? response.meta.total : 0,
                    loading_leads: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_leads: false
                })
            }
        }
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        getLogs: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/log/listLogs?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + (this.state.status.id !== 0 ? "&type=" + this.state.status.value : '') + (this.state.enterprise.id !== 0 ? "&enterprise=" + this.state.enterprise.id : '') + "&version=" + this.state.version.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        enterpriseType: (enterprise) => {
            let string = '';
            try {
                if (enterprise.version) {
                    string += '<span class="text-sm text-indigo-500 uppercase">' + enterprise.version.name + ' • </span>';
                }
                if (enterprise.legacy && enterprise.legacy.value == "enabled") {
                    string += '<span class="text-sm text-pink-500 uppercase">SCALE</span>';
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") !== -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> PROJECTS</span>';
                    }
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") === -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> UNITS</span>';
                    }
                    if (enterprise.basicSwitch && enterprise.basicSwitch.value == "enabled") {
                        string += '<span class="text-sm text-orange-500 uppercase"> • BASIC</span>';
                    }
                    if (enterprise.creativeSwitch && enterprise.creativeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-yellow-600 uppercase"> • CREATIVE</span>';
                    }
                    if (enterprise.proptreeSwitch && enterprise.proptreeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-purple-500 uppercase"> • PROPTREE</span>';
                    }
                } else {
                    string += '<span class="text-sm text-indigo-500 uppercase">LEGACY</span>';
                }
            } catch (error) { }
            return string;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*LIST LOGS*/}
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">

                                    <div className="flex items-center p-6 border-b flex-row">
                                        <div className="flex flex-row">
                                            <div className="rounded-full mr-3 h-12 w-12 border overflow-hidden justify-center flex items-center bg-purple-100 border-purple-500">
                                                <div>
                                                    <BellIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">Manage logs</h3>
                                                <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-1"></div>
                                    </div>

                                    {/*FILTER USERS*/}
                                    <div className="grid sm:grid-cols-6 pb-6 gap-6 px-4 sm:px-6 pt-4">
                                        <div className={"sm:col-span-6 col-gap-6"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                Enterprise
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    loader={this.state.loading_enterprise}
                                                    select={(option) => {
                                                        this.setState({
                                                            enterprise: option,
                                                            page: 1,
                                                        }, () => {
                                                            this.functions.getLogs();
                                                        });
                                                    }}
                                                    disabled={this.state.loading_leads}
                                                    selected={this.state.enterprise}
                                                    options={this.state.enterprises}
                                                />
                                            </div>
                                        </div>
                                        {
                                            false &&
                                            <div className={"sm:col-span-3 col-gap-6"}>
                                                <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                    Status
                                                </label>
                                                <div className="mt-1">
                                                    <SingleSelection
                                                        loader={this.state.loading_enterprise}
                                                        select={(option) => {
                                                            this.setState({
                                                                page: 1,
                                                                status: option
                                                            }, () => {
                                                                this.functions.getLogs();
                                                            });
                                                        }}
                                                        disabled={this.state.loading_leads}
                                                        selected={this.state.status}
                                                        options={this.state.stats}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {
                                        !this.state.loading_leads &&
                                        this.state.leads.length < 1 &&
                                        <div className="w-full h-56 flex justify-center items-center">
                                            <div className="font-medium text-sm">
                                                No <span className="text-purple-500">Logs</span> available
                                            </div>
                                        </div>
                                    }

                                    {
                                        !this.state.loading_leads &&
                                        this.state.leads.length > 0 &&
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto sm:-mx-0 lg:-mx-0">
                                                <div className="align-middle inline-block min-w-full sm:px-0 lg:px-0">
                                                    <div className="border-t border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Created
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Enterprise
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Agent
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Listing
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Type
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Remark
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Data
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Params
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {this.state.leads.map((person) => (
                                                                    <tr key={person.email}>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.createdAt ? moment(person.createdAt).format('YYYY-MM-DD hh:mm A') : "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center">
                                                                                {
                                                                                    person.enterprise && person.enterprise.logo &&
                                                                                    <div className="h-10 min-w-10 rounded-full overflow-hidden flex items-center justify-center border">
                                                                                        <img className="w-10" src={person.enterprise.logo} alt="" />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    person.enterprise &&
                                                                                    <div className="ml-4">
                                                                                        <div className="text-sm font-medium text-gray-900 capitalize">{person.enterprise.name}</div>
                                                                                        <div className="text-sm text-gray-500">{person.enterprise.companyName}</div><div dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(person.enterprise) }}></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.agent ? person.agent : "unknown"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.listing ? person.listing : "unknown"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.type ? person.type : "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.error ? person.error : "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center text-sm font-medium">
                                                                                {person.data ? person.data : "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center text-sm font-medium">
                                                                                {person.params ? person.params : "-"}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.loading_leads &&
                                        <div className="w-full h-64 relative bg-white flex justify-center items-start border-t rounded-b-md">
                                            <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </section>
                        }

                        {
                            !this.state.loading_leads &&
                            this.state.leads.length > 0 &&
                            <div className="rounded-b-md bg-gray-50">
                                <nav
                                    className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state[this.state.status.value] ? this.state[this.state.status.value] : (this.state.page * this.state.page_count)}</span> of{' '}
                                            <span className="font-medium">{this.state[this.state.status.value]}</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                            <div>
                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                    Page limit: {this.state.page_count}
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {
                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        page_count: item,
                                                                                        page: 1
                                                                                    }, () => {
                                                                                        this.functions.getLogs();
                                                                                    })
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                )}
                                                                            >
                                                                                {item}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    select_all: false,
                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                }, () => {
                                                    this.functions.getLogs();
                                                });
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Previous
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                    this.setState({
                                                        select_all: false,
                                                        page: this.state.page + 1
                                                    }, () => {
                                                        this.functions.getLogs();
                                                    });
                                                }
                                            }}
                                            className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Next
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListLogs;
