import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";

class TermsPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    functions = {

    };

    calls = {

    };

    render() {
        return (
            <>
                <div className="text-sm font-medium mx-auto max-w-5xl sm:px-6 lg:px-8 h-full min-h-full py-8">
                    <div>Terms</div>
                    <div>2023.02.01</div>
                    <br />

                    {/* Section 1 */}
                    <h2 className="font-bold">1. PRECONDITIONS</h2>
                    <p>
                        This Master Service Agreement ("Agreement") includes the attached Addendums and all other agreements,
                        policies, and documents incorporated by reference herein and is a legal agreement between you and any
                        company you represent (collectively, "You" and "Your") and Adbooster LLC, PO Box 38115, Dubai, United
                        Arab Emirates, AE211291901 ("Adbooster"). This Agreement applies to (1) all subscriptions for Adbooster
                        hosted Software as a Service ("SaaS") solutions (including but not limited to web-based sales and
                        marketing automation software solutions for businesses, marketers and entrepreneurs) and (2) any other
                        related services that Adbooster may provide to You in connection with such SaaS solutions. Please read
                        the Agreement carefully before continuing Your subscription registration. By accepting this agreement as
                        set forth in any online, e-mail or printed order form referencing this Agreement, You and any company
                        You represent agree to follow and be bound by the terms and conditions of this Agreement. If You are
                        agreeing to this Agreement on behalf of Your company, You are representing to us that You have the
                        authority to bind Your company to this agreement, and the term "You" shall refer to Your company. If You
                        do not have such authority, or if You do not agree to all terms and conditions of this Agreement, You
                        shall not be permitted to use the Adbooster service.
                    </p>
                    <br />

                    {/* Section 2 */}
                    <h2 className="font-bold">2. ADDENDUMS AND REFERENCE</h2>
                    <p>Advertising Services ("Addendum 1")</p>
                    <p>Landing Page Services ("Addendum 2")</p>
                    <p><a href="https://proptr.ee/policy/privacy" target="_blank">Privacy Policy</a></p>
                    <p><a href="https://proptr.ee/policy/use" target="_blank">Acceptable Use Policy</a></p>
                    <br/>

                    {/* Section 3 */}
                    <h2 className="font-bold">3. DEFINITIONS</h2>
                    <p>
                        ("Agreement"), means this agreement along with all related Addendums, agreements, policies and documents;<br />
                        ("Authorized User"), means any of Your employees, consultants, contractors or agents authorized by Your
                        administrator to access and use the Adbooster Service on behalf of Your business, in each case subject to
                        such person's agreement to be bound by the terms of this Agreement;<br />
                        ("Front End Code"), means our user interface display and usability platform. This includes, but is not
                        limited to, the layout, color scheme, HTML pages and source code, etc;<br />
                        ("Adbooster Materials"), means any documentation, user guides or other similar materials provided by
                        Adbooster to You in connection with Your use of the Adbooster Service;<br />
                        ("Adbooster Service"), means any of the Adbooster set of SaaS solutions that are developed, operated,
                        and maintained by Adbooster (and its third party service providers) and that are subscribed to through an
                        Adbooster branded or controlled website (or Adbooster partner website) that includes a link to this
                        Agreement. The definition of Adbooster Service does not include any separate professional Services (as
                        defined below) that may be purchased by You from Adbooster;<br />
                        ("Order Form"), means any online or written subscription order form for the Adbooster Service or for
                        Services submitted by You either during an online subscription process or separately signed by You and
                        submitted to Adbooster, and any future purchase order or order form that makes reference to this
                        Agreement;<br />
                        ("Adbooster"), means Adbooster LLC;<br />
                        ("Sales Channel"), means any Adbooster branded or controlled website (or Adbooster partner website);<br />
                        ("Services"), means any implementation, training or other professional services provided by Adbooster to
                        You pursuant to the terms of an Order Form;<br />
                        ("Subscription Term"), means the use term for the Adbooster Service set forth on Your Order Form and any
                        additional renewals of such term;<br />
                        ("Third Party Content"), means the content, including software code, that an Adbooster partner or other
                        third party may bundle with the Adbooster Service, for a specific market or niche offering;<br />
                        ("You") or ("Yours"), means you and any company you represent;<br />
                        ("Your Data"), means registration information, information concerning Your Authorized Users and customers
                        and contacts, business, marketing and financial information, and any similar data that You upload to the
                        Adbooster Service.
                    </p>
                    <br />

                    {/* Section 4 */}
                    <h2 className="font-bold">4. USE RIGHTS AND RESTRICTIONS</h2><br/>
                    <h3 className="font-bold">4.1 Use Rights; Restrictions</h3>
                    <p>
                        Subject to the terms of this Agreement, Adbooster grants to You during the Subscription Term the
                        non-transferable (except as permitted below), non-exclusive right to permit Your Authorized Users to
                        access and use the Adbooster Service (and any Adbooster Materials provided to You) to allow You to
                        perform contact management, automated marketing, lead tracking and other related business functions that
                        the Adbooster Service is designed to perform, subject to the following restrictions:
                    </p>
                        <p>
                            Your use of the Adbooster Service may not be on behalf of third parties unless a separate agreement
                            between You and Adbooster permits use of the Adbooster Service on behalf of Your clients (and in
                            such case limited to use on behalf of clients for whom You have purchased access and use rights);
                        </p>
                        <p>
                            Except as expressly permitted herein or in a separate partner agreement between You and Adbooster,
                            You may not license, sell, rent, lease, transfer, assign, distribute, display, host, outsource
                            otherwise commercially exploit or make the Adbooster Service or the Adbooster Materials available to
                            any third party;
                        </p>
                        <p>
                            You may not modify, make derivative works of, disassemble, reverse compile, or reverse engineer any
                            part of the Adbooster Service or Adbooster Materials (provided that reverse engineering is
                            prohibited only to the extent such prohibition is not contrary to applicable law), or access or use
                            the Adbooster Service or Adbooster Materials in order to build a similar or competitive product or
                            service;
                        </p>
                        <p>
                            Your use of the Adbooster Service (in terms of number of Authorized Users, maximum list sizes,
                            monthly email limitations, etc.) shall conform with the restrictions set forth in the Order Form for
                            the level of subscription purchased by You (Adbooster may monitor Your compliance with these limits
                            and if it detects overuse require that You upgrade to the appropriate higher subscription level);
                        </p>
                        <p>
                            Your use of the Adbooster Service must not cause undue strain or stress on the Adbooster network
                            through excessive API calls or other non-standard use;
                        </p>
                        <p>
                            Your use of the Adbooster Service must comply with the separate Adbooster Acceptable Use Policy as
                            updated by Adbooster from time to time.
                        </p>
                    <br />

                    <h3 className="font-bold">4.2 Technical Support</h3>
                    <p>
                        During the Subscription Term, You will be entitled at no extra charge to access online user guides,
                        knowledge bases and self-help tools, and any additional standard technical support resources
                        (collectively, "Technical Support") for the Adbooster Service offered by Adbooster from time to time,
                        the terms of conditions of which may be described and updated from time to time on the support or
                        customer care sections of the relevant Adbooster Sales Channel website. Adbooster reserves the right to
                        modify the posted terms and conditions for Technical Support, at any time at its sole discretion.
                    </p>
                    <br />

                    <h3 className="font-bold">4.3 Intellectual Property Rights</h3>
                    <p>
                        Adbooster shall retain all right, title and interest (including all copyrights, patents, service marks,
                        trademarks and other intellectual property rights) in and to the Adbooster Service and Adbooster
                        Materials (including application development, business and technical methodologies, and implementation
                        and business processes, used by Adbooster to develop or provide the Adbooster Service or Adbooster
                        Materials), and any and all updates, enhancements, customizations, revisions, modifications, future
                        releases and any other changes relating to any of the foregoing. Except for the limited access and use
                        rights granted pursuant to this Agreement, You do not acquire any interest in the Adbooster Service or
                        Adbooster Materials. You agree that any suggestions, enhancement requests, feedback, recommendations or
                        other information provided by You or any of Your Authorized Users relating to the Adbooster Service or
                        the Adbooster Materials may be used by Adbooster without restriction or obligation to You.
                    </p>
                    <br />

                    <h3 className="font-bold">4.4 Additional Restrictions</h3>
                    <p>
                        You are expressly prohibited from using any Front End Code for any purpose outside of the intended
                        design and implementation of Your authorized use of the Adbooster Service. Any replication or use of any
                        aspect of the Front End Code or other Adbooster application or Services for any purpose designed or
                        intended to compete with Adbooster's solutions is strictly prohibited.
                    </p>
                    <br />

                    <h3 className="font-bold">4.5 Ownership of Your Data</h3>
                    <p>
                        As between You and Adbooster, Your Data and any similar data provided to Adbooster outside of the
                        registration process (either in hard copy or electronic format) is and shall remain Your property. To
                        enable Adbooster to provide You with the Adbooster Service, and subject to the terms and conditions of
                        this Agreement, You hereby grant to Adbooster a non-exclusive right to use, copy, distribute and display
                        Your Data solely in connection with Adbooster's operation of the Adbooster Service on Your behalf. You,
                        not Adbooster, shall have sole responsibility for the accuracy, integrity, and reliability of Your Data,
                        and Adbooster will not be responsible or liable for the deletion, correction, destruction, damage, loss
                        or failure to store any of Your Data. Adbooster will protect any of Your Data provided to Adbooster as
                        confidential in accordance with Article 6 below.
                    </p>
                    <br />

                    {/* Section 5 */}
                    <h2 className="font-bold">5. FEES</h2><br/>
                    <h3 className="font-bold">5.1 Fees</h3>
                    <p>
                        The fees for the Adbooster Service and any additional Services ("Fees") are set forth in the Order Form
                        and are payable in advance, irrevocable and non-refundable except as set forth in the Order Form and
                        this Agreement. You agree to provide Adbooster with complete and accurate billing and contact
                        information. Where payment by credit card is indicated in the Order Form, or You otherwise provide
                        Adbooster with credit card information, You authorize Adbooster to bill such credit card (a) at the time
                        that You order the Adbooster Service or other Services set forth in the Order Form, (b) for any billing
                        frequency otherwise established in the Order Form, and (c) at the time of any renewal, for the amount
                        charged plus any applicable sales taxes for any renewed Subscription Term. If Adbooster, in its
                        discretion, permits You to make payment using a method other than a credit card, Adbooster will invoice
                        You at the time of the initial Order Form and thereafter on a monthly basis in advance of the relevant
                        billing period, and all such amounts invoiced will be due within ten (10) days of Your receipt of
                        Adbooster's invoice. Late payments shall be subject to a service charge of one and one-half percent
                        (1.5%) per month, or the maximum charge permitted by law, whichever is less. Adbooster reserves the right
                        to withhold a deposit when applicable. The deposit may be used to cover any excess marketing spend related
                        to Advertising Services, non-payment of Fees, or any unsuccessful debit on the billing date.
                    </p>
                    <br />

                    <h3 className="font-bold">5.2 Taxes</h3>
                    <p>
                        You shall pay all personal property, sales, use, value-added, withholding and similar taxes (other than
                        taxes on Adbooster's net income) arising from the transactions described in this Agreement, even if such
                        amounts are not listed on an Order Form. To the extent You are exempt from sales or other taxes, You
                        agree to provide Adbooster, upon request, with the appropriate exemption certificate.
                    </p>
                    <br />

                    <h3 className="font-bold">5.3 Non-Payment; Other Suspension Rights</h3>
                    <p>
                        Adbooster may terminate the Adbooster Service if the billing or contact information provided by You is
                        false or fraudulent. Adbooster also reserves the right, in its discretion, to suspend Your access and/or
                        use of the Adbooster Service: (i) where any payment is due but unpaid and You have been requested but
                        failed to promptly cure such payment failure; or (ii) in the event a dispute arises on Your account as
                        to who at Your business has authority to act or manage Your account and Adbooster is not promptly
                        provided with written instructions from the interested parties associated with Your account that fully
                        resolves the dispute. You acknowledge and agree that if a dispute arises as to management of Your
                        account, then (i) if the listed owner of the account is a corporation, limited liability company or
                        other registered entity, Adbooster may rely on public records (to the extent available) concerning the
                        appropriate authorized executives or managers of Your entity; or (ii) if the listed owner is a dba or
                        sole proprietorship, or any other entity for which public records of control are not readily accessible
                        online, Adbooster may assume that the person or entity that has been making payments on Your account has
                        the authority to manage the account. You agree that Adbooster shall not be liable to You nor to any
                        third party for any suspension of the Adbooster Service resulting from Your non-payment of Fees or from
                        a dispute as to the management rights to Your account.
                    </p>
                    <br />

                    <h3 className="font-bold">5.4 Currency</h3>
                    <p>All money amounts referred to in this Agreement are set out in the Order Form.</p>
                    <br />

                    {/* Section 6 */}
                    <h2 className="font-bold">6. CONFIDENTIALITY; USE OF NAMES</h2><br/>
                    <h3 className="font-bold">6.1 Confidential Information</h3>
                    <p>
                        Confidential Information. For purposes of this Agreement, confidential information shall include the
                        business terms in the Order Form, Your Data, the Adbooster Service and the Adbooster Materials, and any
                        information that is clearly identified in writing at the time of disclosure as confidential or that
                        should be reasonably understood to be confidential by the receiving party given the nature of the
                        information and the circumstances of its disclosure ("Confidential Information"). Each party agrees: (a)
                        to receive and maintain in confidence all Confidential Information disclosed to it by the other party or
                        by a third-party; (b) not to use the Confidential Information of the other party except to the extent
                        necessary to perform its obligations or exercise rights hereunder; (c) to limit the internal
                        dissemination of Confidential Information to those employees and contractors of the recipient who have a
                        need to know and an obligation to protect it; and (d) to protect the confidentiality thereof in the same
                        manner as it protects the confidentiality of similar information and data of its own (at all times
                        exercising at least a reasonable degree of care in the protection of such Confidential Information).
                        Adbooster will restrict its employees' access to Your Confidential Information to only those employees
                        necessary to successfully provide the Adbooster Service. Adbooster may disclose Confidential
                        Information on a need-to-know basis to its contractors who have executed written agreements requiring
                        them to maintain such information in strict confidence and use it only to facilitate the performance of
                        their services for Adbooster in connection with the performance of this Agreement. Confidential
                        Information shall not include information that: (1) is known publicly; (2) is generally known in the
                        industry before disclosure; (3) has become known publicly, without fault of the recipient, subsequent to
                        disclosure by the disclosing party; or (4) the recipient becomes aware of from a third party not bound
                        by non-disclosure obligations to the disclosing party and with the lawful right to disclose such
                        information to the recipient. This Section will not be construed to prohibit the disclosure of
                        Confidential Information to the extent that such disclosure is required by law or order of a court or
                        other governmental authority. The parties agree to give the other party prompt notice of the receipt of
                        any subpoena or other similar request for such disclosure.
                    </p>
                    <br />

                    <h3 className="font-bold">6.2 Credit Card Information</h3>
                    <p>
                        Adbooster agrees that it will retain and store any provided credit card information only for the minimum
                        amount of time required for business, legal and/or regulatory purposes, and will use standard industry
                        practices to protect such information from unauthorized access, disclosure or use.
                    </p>
                    <br />

                    <h3 className="font-bold">6.3 Use of Names in Marketing</h3>
                    <p>
                        You may use Adbooster's name and credentials in an appropriate and acceptable manner for Your standard
                        marketing promotions, provided that You agree to cease or alter such use at Adbooster's request where
                        such use is contrary to Adbooster's branding policies, could cause any brand confusion in the market or
                        is otherwise objectionable to Adbooster. Similarly, Adbooster may use Your business name in an
                        appropriate and acceptable manner for standard marketing promotions, provided that Adbooster agrees to
                        cease or alter such use at Your request where such use is contrary to Your branding policies, could
                        cause any brand confusion in the market or is otherwise objectionable to You. Acceptable and standard
                        marketing promotions include, but are not limited to: client listings, press releases, surveys,
                        interviews, reputable business publications, television, and web site presentation and promotion, etc.
                    </p>
                    <br />

                    {/* Section 7 */}
                    <h2 className="font-bold">7. TERM AND TERMINATION</h2><br/>
                    <h3 className="font-bold">7.1 Standard Term</h3>
                    <p>
                        Unless a different Term is specified in a signed Order Form between You and Adbooster, the Initial Term of
                        Your subscription to a Adbooster Service will begin on the submission or execution of Your Order Form and
                        continue until terminated as provided in this Article 7. The Term of this Agreement will automatically renew
                        for the same term unless terminated as outlined in Article 7.2.
                    </p>
                    <br />

                    <h3 className="font-bold">7.2 Termination without Cause</h3>
                    <p>
                        Either party may terminate the Subscription Term to a Adbooster Service by providing thirty (30) days' prior
                        written notice to the other party. However, Termination without Cause by You is only permitted a maximum of
                        sixty (60) days' before the Subscription Term's expiration. If the written notice is not provided at least
                        thirty (30) days' before the expiration of the Term, the Term will automatically renew, and You will remain
                        liable for all Fees for the full renewed Term, regardless of termination. Adbooster's termination rights are
                        in addition to any suspension rights it may have under this Agreement or the incorporated Acceptable Use Policy.
                    </p>
                    <br />

                    <h3 className="font-bold">7.3 Effect of Termination</h3>
                    <p>
                        Upon termination of the Subscription Term, all Fees then due and payable to Adbooster must be paid in
                        full. Contingent upon its receipt of all such Fees, Adbooster will continue to make Your Data available
                        for downloading through the termination date. In addition, for a period of thirty (30) days following
                        termination, You may arrange for the downloading of Your Data by contacting Adbooster. Following this
                        thirty (30) day grace period, Adbooster may remove Your Data from the production environment for the
                        Adbooster Service. The provisions of this Agreement which by their nature are intended to survive
                        expiration or termination shall survive, including but not limited to obligations concerning
                        confidentiality, protection of intellectual property, indemnification and payment of unpaid Fees and
                        expenses.
                    </p>
                    <br />

                    {/* Section 8 */}
                    <h2 className="font-bold">8. WARRANTIES/LIMITATION OF LIABILITY/INDEMNITY</h2><br/>
                    <h3 className="font-bold">8.1 Limited Warranties</h3>
                    <p>
                        Adbooster warrants for a period of thirty (30) days following their delivery that all professional
                        Services provided hereunder will be performed in a workmanlike manner, in conformity with the
                        professional standards for comparable services in the industry. For any breach of this warranty timely
                        reported by You, Your exclusive remedy shall be the re-performance of the deficient Services, and if
                        Adbooster is unable to re-perform the deficient Services as warranted, You shall be entitled to recover
                        the portion of the Fees paid to Adbooster for such deficient Services, and such refund shall be
                        Adbooster's entire liability. You warrant that Your business shall, at all times, comply with, and shall
                        remain solely responsible for compliance with, all applicable federal, state and local laws and
                        regulations, as well as the Adbooster Acceptable Use Policy, in connection with Your use of the
                        Adbooster Service, and You agree to indemnify and hold Adbooster harmless from and against any third
                        party or government claims, including all related damages, costs and expenses (including reasonable
                        attorneys' fees), that arise due to Your violation of law or breach of this warranty in Your use of the
                        Adbooster Service.
                    </p>
                    <p>
                        All third party hardware, including but not limited to card readers, and other products included or sold
                        with the Services are provided solely according to the warranty and other terms specified by the
                        manufacturer, who is solely responsible for service and support for its product. For service, support,
                        or warranty assistance, you should contact the manufacturer directly (see Adbooster Mobile Payments).
                        ADBOOSTER MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH THIRD PARTY PRODUCTS, AND
                        EXPRESSLY DISCLAIMS ANY WARRANTY OR CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
                        PARTICULAR PURPOSE. IN NO EVENT WILL ADBOOSTER BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, OR COVER
                        DAMAGES ARISING OUT OF YOUR USE OF OR INABILITY TO USE THIRD PARTY PRODUCTS OR ANY AMOUNT IN EXCESS OF
                        THE AMOUNT PAID BY YOU FOR THE PRODUCT THAT GIVES RISE TO ANY CLAIM.
                    </p>
                    <br />

                    <h3 className="font-bold">8.2 Adbooster Not Responsible for Third Party Content</h3>
                    <p>
                        The Adbooster Service may be bundled by third parties (including but not limited to Adbooster marketing
                        or content partners) with Third Party Content designed to facilitate use of the Adbooster Service in
                        certain market niches or to customize the Adbooster Service for use by certain categories of target
                        customers. To the extent that You either purchase the Adbooster Service from such third parties or
                        acquire the Third Party Content or configuration services from such third parties (even though you may
                        purchase the core Adbooster Service directly from Adbooster), Adbooster does not warrant in any manner
                        and will not be responsible for such Third Party Content and You agree to look solely to the relevant
                        third party provider (and not Adbooster) if and to the extent that you have any complaints or issues
                        relating to the Third Party Content or its interaction with an Adbooster Service.
                    </p>
                    <br />

                    <h3 className="font-bold">8.3 Warranty Disclaimers</h3>
                    <p>
                        EACH PARTY DISCLAIMS ALL OTHER WARRANTIES, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
                        TITLE, UNINTERRUPTED SERVICE OR FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT ADBOOSTER
                        SPECIFICALLY DISCLAIMS ALL WARRANTIES RELATING TO THE ADBOOSTER SERVICE.
                    </p>
                    <br />

                    <h3 className="font-bold">8.4 Limitation of Liability</h3>
                    <p>
                        IN NO EVENT WILL ADBOOSTER BE LIABLE TO YOU FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
                        CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA, LOSS OF BUSINESS OR OTHER LOSS
                        ARISING OUT OF OR RESULTING FROM THIS AGREEMENT EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. THE FOREGOING SHALL APPLY REGARDLESS OF WHETHER SUCH LIABILITY SOUNDS IN CONTRACT, NEGLIGENCE,
                        TORT, STRICT LIABILITY OR ANY OTHER THEORY OF LEGAL LIABILITY. IN ADDITION, IN NO EVENT WILL
                        ADBOOSTER'S CUMULATIVE LIABILITY UNDER THIS AGREEMENT EXCEED THE AMOUNT PAID BY YOU TO ADBOOSTER DURING
                        THE SIX MONTH PERIOD PRECEDING THE ALLEGED LIABILITY EVENT.
                    </p>
                    <br />

                    {/* Section 9 */}
                    <h2 className="font-bold">9. GENERAL PROVISIONS</h2><br/>
                    <h3 className="font-bold">9.1 Notice</h3>
                    <p>
                        Notices regarding this Agreement to Adbooster shall be in writing and sent by first class mail,
                        overnight courier or international courier, addressed to Adbooster. Adbooster may give notice applicable
                        to Adbooster's general customer base by means of a general notice on the Adbooster Service portal, and
                        notices specific to You by electronic mail to Your designated contact's email address on record with
                        Adbooster, or by written communication sent by first class mail, overnight courier or international
                        courier, to Your address on record in Adbooster's account information. All notices shall be deemed to
                        have been given three (3) days after mailing or posting (if sent by first class mail), upon delivery in
                        the case of courier, or twelve (12) hours after sending by confirmed facsimile, email or posting to the
                        Adbooster Service portal.
                    </p>
                    <br />

                    <h3 className="font-bold">9.2 Assignment</h3>
                    <p>
                        You may not assign this Agreement without providing prior notice to and obtaining the consent of
                        Adbooster, which shall not be unreasonably denied provided Your account is in good standing. Any
                        purported assignment in violation of this Section shall be void.
                    </p>
                    <br />

                    <h3 className="font-bold">9.3 Integration; Modification</h3>
                    <p>
                        This Agreement and the information incorporated into this Agreement by written reference (including
                        reference to information contained in a URL or referenced Addendum), together with any applicable Order
                        Form, represent the parties' entire understanding relating to the Adbooster Service, the Adbooster
                        Materials and the Services, and supersede any prior or contemporaneous, conflicting or additional
                        communications. The terms and conditions of this Agreement may only be amended by written agreement of
                        the parties.
                    </p>
                    <br />

                    <h3 className="font-bold">9.4 Governing Law; Arbitration</h3>
                    <p>
                        This Agreement shall be governed by the laws of United Arab Emirates without giving effect to conflict
                        of laws principles. Any and all disputes, controversies and claims arising out of or relating to this
                        Agreement or concerning the respective rights or obligations of the parties hereto shall be settled and
                        determined by arbitration before a panel of one (1) arbitrator, pursuant to the Commercial Rules of the
                        Swedish Arbitration Association then in effect. Judgment upon the award rendered may be entered in any
                        court having jurisdiction or application may be made to such court for a judicial acceptance of the
                        award and an order of enforcement. The parties agree that the arbitrator shall have the power to award
                        damages, injunctive relief and reasonable attorneys' fees and expenses to the prevailing party.
                    </p>
                    <br />

                    <h3 className="font-bold">9.5 Force Majeure</h3>
                    <p>
                        Except for Your obligation to pay Fees for the Adbooster Service or other Services rendered, neither
                        party will be responsible for failure of performance due to causes beyond its control. Such causes
                        include (without limitation) accidents, acts of God, labor disputes, actions of any government agency,
                        shortage of materials, acts of terrorism, or the stability or availability of the Internet or a portion
                        thereof.
                    </p>
                    <br />

                    <h3 className="font-bold">9.6 Export</h3>
                    <p>
                        You agree that export and import laws govern Your use of the Adbooster Service, including Adbooster
                        technology. You represent that You are neither a citizen of an embargoed country nor prohibited end user
                        under applicable export or anti-terrorism laws, regulations and lists. You agree not to use or export,
                        nor allow a third party to use or export, the Adbooster Service or technology in any manner that would
                        violate applicable law, including but not limited to applicable export and import control laws and
                        regulations.
                    </p>
                    <br />

                    <h3 className="font-bold">9.7 Severability</h3>
                    <p>
                        If any provision of this Agreement is determined to be illegal or unenforceable, that provision will be
                        limited to the minimum extent necessary so that this Agreement shall otherwise remain in full force and
                        effect.
                    </p>
                    <br />

                    <h3 className="font-bold">9.8 Relationship of Parties</h3>
                    <p>
                        No joint venture, partnership, employment, or agency relationship exists between Adbooster and You as a
                        result of this Agreement or use of the Adbooster Service.
                    </p>
                    <br />

                    <h3 className="font-bold">9.9 Waiver</h3>
                    <p>
                        The failure of either party to enforce any right or provision in this Agreement shall not constitute a
                        waiver of such right or provision unless acknowledged and agreed to by such party in writing.
                    </p>
                    <br />

                    <h3 className="font-bold">9.10 Invalidity; Waivers</h3>
                    <p>
                        If any provision or portion of this Agreement is held invalid, illegal, void or unenforceable as it
                        appears in this Agreement by reason of any rule of law, administrative or judicial provision or public
                        policy, then such provision shall be construed as being enforceable to the extent such rule of law,
                        administrative or judicial provision or public policy allows. All other provisions of this Agreement
                        shall nevertheless remain in full force and effect. Neither of the parties shall be deemed to have
                        waived any of its rights, powers or remedies hereunder unless the waiving party expresses such a waiver
                        in writing.
                    </p>
                    <br />

                    <h3 className="font-bold">9.11 Government End Use</h3>
                    <p>
                        If the Government has a need for rights not conveyed under this Agreement, it must negotiate with
                        Adbooster to determine if there are acceptable terms for transferring such rights, and a mutually
                        acceptable written addendum specifically conveying such rights must be included in any applicable
                        contract or agreement.
                    </p>
                    <br />

                    {/* Addendum 1 */}
                    <h2 className="font-bold">ADDENDUM 1 | ADVERTISING SERVICES</h2>
                    <p>
                        This advertising services addendum ("Addendum") is a legal agreement between you and any company you
                        represent (collectively, "You" and "Your") and Adbooster LLC ("Adbooster").
                    </p>
                    <p>
                        When you purchase "Advertising Services" (as defined below) from Adbooster, this Addendum applies to all
                        Advertising Services that Adbooster may provide to You in connection with the Adbooster Services You
                        use, and this Addendum serves to modify and is made a part of Adbooster’s "Master Service Agreement"
                        that already apply to Your use of the Adbooster Services. In the event that this Addendum conflicts with
                        the Master Service Agreement, for purposes of the Advertising Services only, this Addendum will prevail.
                    </p>
                    <p>
                        Please read this Addendum carefully before completing Your purchase. By accepting this Addendum as set
                        forth in any order form (including electronic or email order forms) referencing this Addendum, You and
                        any company You represent agree to follow and be bound by the terms and conditions of this Addendum and
                        the Master Service Agreement. If You are agreeing to this Addendum and the Master Service Agreement on
                        behalf of Your company, You are representing to us that You have the authority to bind Your company, and
                        the term "You" shall refer to Your company. If You do not have such authority, or if You do not agree to
                        all terms and conditions of this Addendum and the Master Service Agreement, You shall not be permitted
                        to use the Adbooster Advertising Services.
                    </p>
                    <p>
                        Because You’ll be using the Advertising Services to use yourself or as an agent for the actual
                        advertising person or entity, we’ll refer to You as the "Advertiser" in this Addendum, regardless of
                        which situation applies. You agree that Your acceptance of this Addendum will legally bind any such
                        advertisers, that You are legally authorized to bind them in this manner, and that You and the advertiser
                        you represent are jointly and separately (severally) bound by Your acceptance of this Addendum as if You
                        each separately consented to it.
                    </p>
                    <br />

                    <h3 className="font-bold">1. Advertising Services and Respective Obligations</h3>
                    <p>
                        1.1 In performing and providing the "Advertising Services" Adbooster will market Advertiser’s
                        business/service through select online methods, websites, and the like, often through partnerships with
                        other companies or services, such as Google, Facebook, Bing, LinkedIn, Twitter, Instagram. Advertising
                        Services offered by Adbooster will change from time to time and may be discontinued by Adbooster at any
                        time.
                    </p>
                    <p>
                        1.2 This Addendum applies only to the specific Advertising Services you request and pay for and
                        Adbooster provides.
                    </p>
                    <p>
                        1.3 Advertiser understands that clicks to Advertiser’s site, including clicks on search engines,
                        shopping engines, content sites, and the like, may include singular/plural combinations, misspellings,
                        and other related but not exactly Advertiser-matched search terms that Adbooster uses to map to
                        Advertiser’s campaigns.
                    </p>
                    <p>
                        1.4 Advertiser is solely responsible for the quality and accuracy of its own and its clients’ (if
                        applicable) websites, landing pages and redirect websites that link to ads. In case Adbooster provides a
                        landing page Advertiser has to agree with the terms and conditions of the Landing Page Services.
                    </p>
                    <p>
                        1.5 Advertiser agrees to remain responsible for, and will not hold Adbooster or its partners, if
                        applicable, responsible for, the activities of visitors who come to Advertiser's websites through or as
                        a result of the Advertising Services.
                    </p>
                    <br />

                    <h3 className="font-bold">2. Advertising Services and Respective Obligations</h3>
                    <p>
                        Fees and pricing for the Advertising Services are set forth on your Order Form; by accepting this
                        Addendum, Advertiser expressly agrees to pay the fees and pricing for those Advertising Services.
                    </p>
                    <br />

                    <h3 className="font-bold">3. Advertising Services and Responsibility</h3>
                    <p>
                        Advertiser agrees and acknowledges Advertiser, and not Adbooster or any partner of Adbooster, is solely
                        responsible for Advertiser’s website design, creation, and operation, including website content and
                        visitors, and for the general operation of Advertiser’s business, both via such websites and otherwise.
                        In case Adbooster provides a landing page Advertiser has to agree with the terms and conditions of the
                        Landing Page Services.
                    </p>
                    <br />

                    <h3 className="font-bold">4. Advertiser Representations and Warranties</h3>
                    <p>
                        4.1 Advertiser represents and warrants to Adbooster and its partners (if applicable) that:
                    </p>
                    <p>
                        4.2 Advertiser is responsible for its own responsiveness to communications and inquiries from customers
                        and from Adbooster and its partners in relation to the Advertising Services, and Advertiser acknowledges
                        that failure to timely respond could detrimentally affect the Advertising Services’ effectiveness;
                    </p>
                    <p>
                        4.3 All information and materials Advertiser provides Adbooster or its partners in connection with
                        Advertising Services is accurate and complete, and Advertiser is legally authorized to use it;
                    </p>
                    <p>
                        4.4 Advertiser either owns, or has valid administrative rights and/or agency arrangements in place for,
                        all websites the Advertising Services relate to; and
                    </p>
                    <p>
                        4.5 The information, materials and websites referenced in the two paragraphs above, as provided by
                        Advertiser to Adbooster or its partners: do not violate any applicable law or infringe any third party
                        rights; are not false or misleading; have not given and will not give rise to any liability in contract
                        or tort to any third party; and do not contain, promote, or offer any form of virus, or any malware,
                        spyware, adware, or similar advertising or information collection software.
                    </p>
                    <br />

                    <h3 className="font-bold">5. Use of Information</h3>
                    <p>
                        Advertiser agrees that Adbooster may share all data it obtains, including product performance data, with
                        its partners in providing the Advertising Services, who shall have the same rights to access and use
                        said data as Adbooster itself and shall be a beneficiary of the terms and conditions of this Addendum.
                    </p>
                    <br />

                    <h3 className="font-bold">6. Indemnification</h3>
                    <p>
                        With respect to the Advertising Services, Advertiser warrants that your business shall, at all times,
                        comply with, and shall remain solely responsible for compliance with, all applicable federal, state and
                        local laws and regulations, as well as the Adbooster Acceptable Use Policy, in connection with your use
                        of the Advertising Services, and you agree to indemnify and hold Adbooster and its partner in providing
                        the Adbooster Services, if applicable, harmless from and against any third party or government claims,
                        including all related damages, costs and expenses (including reasonable attorneys' fees), that arise due
                        to your breach of this warranty.
                    </p>
                    <br />

                    <h3 className="font-bold">7. Limitation of Liability and Warranty Disclaimer</h3>
                    <p>
                        In addition to the limitation of liability and warranty disclaimer provisions set forth in the Master
                        Service Agreement:
                    </p>
                    <p>
                        7.1 Advertiser acknowledges and agrees that it will not hold Adbooster or its partner, if applicable,
                        liable for any errors in content, omissions, consequences, damages, costs, refunds, or rebates of any
                        kind arising from any interruption of service or other unavailability of the Internet or websites in
                        which the advertisements are published for whatever reason. Advertiser acknowledges and agrees that
                        errors or mistakes in the performance of the Advertising Services, including but not limited to
                        misspellings or miscommunications, do not create a right to refund for Advertiser. Advertiser will give
                        Adbooster timely notice of any such errors or omissions and allow reasonable time to cure them.
                    </p>
                    <p>
                        7.2 Adbooster makes no representations or warranties relating to the results of Advertising Services and
                        makes no guarantees regarding the accuracy, reliability, or completeness of any third party data. In no
                        event shall Adbooster or its partner, if applicable, be responsible for any consequential, special, lost
                        profits, or other damages arising under this Addendum.
                    </p>
                    <br />

                    {/* Addendum 2 */}
                    <h2 className="font-bold">ADDENDUM 2 | LANDING PAGE SERVICES</h2>
                    <p>
                        This landing page services addendum ("Addendum") is a legal agreement between you and any company you
                        represent (collectively, "You" and "Your") and Adbooster LLC ("Adbooster").
                    </p>
                    <p>
                        When you purchase "Landing Page Services" (as defined below) from Adbooster, this Addendum applies to
                        all Landing Page Services that Adbooster may provide to You in connection with the Adbooster Services
                        You use, and this Addendum serves to modify and is made a part of Adbooster’s "Master Service Agreement"
                        that already apply to Your use of the Adbooster Services. In the event that this Addendum conflicts with
                        the Master Service Agreement, for purposes of the Landing Page Services only, this Addendum will
                        prevail.
                    </p>
                    <p>
                        Please read this Addendum carefully before completing Your purchase. By accepting this Addendum as set
                        forth in any order form (including electronic or email order forms) referencing this Addendum, You and
                        any company You represent agree to follow and be bound by the terms and conditions of this Addendum and
                        the Master Service Agreement. If You are agreeing to this Addendum and the Master Service Agreement on
                        behalf of Your company, You are representing to us that You have the authority to bind Your company, and
                        the term "You" shall refer to Your company. If You do not have such authority, or if You do not agree to
                        all terms and conditions of this Addendum and the Master Service Agreement, You shall not be permitted
                        to use the Adbooster Landing Page Services.
                    </p>
                    <p>
                        Because You’ll be using the Landing Page Services to use yourself or as an agent for the actual
                        advertising person or entity, we’ll refer to You as the "Advertiser" in this Addendum, regardless of
                        which situation applies. You agree that Your acceptance of this Addendum will legally bind any such
                        advertisers, that You are legally authorized to bind them in this manner, and that You and the advertiser
                        you represent are jointly and separately (severally) bound by Your acceptance of this Addendum as if You
                        each separately consented to it.
                    </p>
                    <br />

                    <h3 className="font-bold">1. Landing Page Services and Respective Obligations</h3>
                    <p>
                        1.1 In performing and providing the "Landing Page Services" Adbooster will provide the Advertiser’s
                        business/service with a landing page for its advertising often through partnerships with other companies
                        or services. Landing Page Services offered by Adbooster will change from time to time and may be
                        discontinued by Adbooster at any time.
                    </p>
                    <p>
                        1.2 This Addendum applies only to the specific Landing Page Services you request and pay for and
                        Adbooster provides.
                    </p>
                    <p>
                        1.3 Advertiser is solely responsible for the quality and accuracy of its own and its clients’ (if
                        applicable) landing pages.
                    </p>
                    <p>
                        1.4 Advertiser agrees to remain responsible for, and will not hold Adbooster or its partners, if
                        applicable, responsible for, the activities of visitors who come to Advertiser's landing page through or
                        as a result of the Landing Page Services.
                    </p>
                    <br />

                    <h3 className="font-bold">2. Landing Page Services and Respective Obligations</h3>
                    <p>
                        Fees and pricing for the Landing Page Services are set forth on your Order Form; by accepting this
                        Addendum, Advertiser expressly agrees to pay the fees and pricing for those Landing Page Services.
                    </p>
                    <br />

                    <h3 className="font-bold">3. Landing Page Services and Responsibility</h3>
                    <p>
                        Advertiser agrees and acknowledges Advertiser, and not Adbooster or any partner of Adbooster, is solely
                        responsible for Advertiser’s landing page content and visitors, and for the general operation of
                        Advertiser’s business, both via such landing pages and otherwise.
                    </p>
                    <br />

                    <h3 className="font-bold">4. Advertiser Representations and Warranties</h3>
                    <p>
                        Advertiser represents and warrants to Adbooster and its partners (if applicable) that:
                    </p>
                    <p>
                        4.1 Advertiser is responsible for its own responsiveness to communications and inquiries from customers
                        and from Adbooster and its partners in relation to the Landing Page Services, and Advertiser acknowledges
                        that failure to timely respond could detrimentally affect the Landing Page Services’ effectiveness;
                    </p>
                    <p>
                        4.2 All information and materials Advertiser provides Adbooster or its partners in connection with
                        Landing Page Services is accurate and complete, and Advertiser is legally authorized to use it;
                    </p>
                    <p>
                        4.3 Advertiser owns the content submitted to the Landing Page Services; and
                    </p>
                    <p>
                        4.4 The information, materials and websites referenced in the two paragraphs above, as provided by
                        Advertiser to Adbooster or its partners: do not violate any applicable law or infringe any third party
                        rights; are not false or misleading; have not given and will not give rise to any liability in contract
                        or tort to any third party; and do not contain, promote, or offer any form of virus, or any malware,
                        spyware, adware, or similar advertising or information collection software.
                    </p>
                    <br />

                    <h3 className="font-bold">5. Use of Information</h3>
                    <p>
                        Advertiser agrees that Adbooster may share all data it obtains, including content, with its partners in
                        providing the Landing Page Services, who shall have the same rights to access and use said data as
                        Adbooster itself and shall be a beneficiary of the terms and conditions of this Addendum.
                    </p>
                    <br />

                    <h3 className="font-bold">6. Indemnification</h3>
                    <p>
                        With respect to the Landing Page Services, Advertiser warrants that your business shall, at all times,
                        comply with, and shall remain solely responsible for compliance with, all applicable federal, state and
                        local laws and regulations, as well as the Adbooster Acceptable Use Policy, in connection with your use
                        of the Landing Page Services, and you agree to indemnify and hold Adbooster and its partner in providing
                        the Adbooster Services, if applicable, harmless from and against any third party or government claims,
                        including all related damages, costs and expenses (including reasonable attorneys' fees), that arise due
                        to your breach of this warranty.
                    </p>
                    <br />

                    <h3 className="font-bold">7. Limitation of Liability and Warranty Disclaimer</h3>
                    <p>
                        In addition to the limitation of liability and warranty disclaimer provisions set forth in the Master
                        Service Agreement:
                    </p>
                    <p>
                        7.1 Advertiser acknowledges and agrees that it will not hold Adbooster or its partner, if applicable,
                        liable for any errors in content, omissions, consequences, damages, costs, refunds, or rebates of any
                        kind arising from any interruption of service or other unavailability of the Internet or websites in
                        which the advertisements are published for whatever reason. Advertiser acknowledges and agrees that
                        errors or mistakes in the performance of the Landing Page Services, including but not limited to
                        misspellings or miscommunications, do not create a right to refund for Advertiser. Advertiser will give
                        Adbooster timely notice of any such errors or omissions and allow reasonable time to cure them.
                    </p>
                    <p>
                        7.2 Adbooster makes no representations or warranties relating to the results of Landing Page Services
                        and makes no guarantees regarding the accuracy, reliability, or completeness of any third party data. In
                        no event shall Adbooster or its partner, if applicable, be responsible for any consequential, special,
                        lost profits, or other damages arising under this Addendum.
                    </p>
                </div>
            </>
        )

    }
}

export default TermsPolicy;
