import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';
import Switch from './switch';

class CreateNewFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            adbooster_type: { id: 1, name: "Property Booster" },
            dontShowSubmitButton: false,
            preview: null,
            preview_params_missing: true
        }
    };

    componentDidMount() { }

    async componentWillReceiveProps(nextProps) {

        let user = userRegister.get();
        await this.promisedSetState({ user: user });
        if (!this.state.open && nextProps.open) {
            this.functions.getListings();
            this.state.feed = (nextProps.feed ? nextProps.feed : {});
            this.state.preview = null;
            this.state.listing = null;
            this.state.preview_params_missing = true;
        }

        let market = this.state.user.enterprise && this.state.user.enterprise.market && this.state.user.enterprise.market.value ? this.state.user.enterprise.market.value : "ae";
        let currency = this.state.user.enterprise && this.state.user.enterprise.baseCurrency && this.state.user.enterprise.baseCurrency.value ? this.state.user.enterprise.baseCurrency.value.toUpperCase() : "AED";

        this.setState({
            preview_params_missing: this.state.preview_params_missing,
            preview: this.state.preview,
            listing: this.state.listing,
            base_language: this.renders.localize(market, "base_language", currency),
            base_currency: this.renders.localize(market, "base_currency", currency),
            base_size_unit: this.renders.localize(market, "base_size_unit", currency),
            base_dial_code: this.renders.localize(market, "base_dial_code", currency),
            feed: this.state.feed,
            open: nextProps.open,
            user_enterprise: nextProps.enterprise,
            dontShowSubmitButton: nextProps.dontShowSubmitButton
        })
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            if (this.props.admin) {
                this.functions.getEnterprises();
            }
        },
        getEnterprises: async () => {
            try {
                await this.promisedSetState({ loading_enterprise: true });
                let response = await this.calls.getEnterprises();
                this.setState({
                    enterprises: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading: false,
                    loading_enterprise: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_enterprise: false
                })
            }
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data.map((item) => {
                        if (this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) {
                            item.name = item.projectName + "  #" + item.reference;
                        } else {
                            item.name = item.name + "  #" + item.reference;
                        }
                        return item;
                    })
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_listings: false });
        },
        getForms: async () => {
            try {
                await this.promisedSetState({ loading_forms: true });
                let response = await this.calls.getForms();
                this.setState({
                    forms: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading_forms: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_forms: false
                })
            }
        },
        createFeed: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let feed = JSON.parse(JSON.stringify(this.state.feed));
                if (this.renders.customDisabled()) {
                    feed.custom = { id: 0, name: 'Disabled', value: 'disabled' };
                }
                await this.calls.createFeed(feed);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        createPreview: async () => {
            try {
                if (this.state.listing) {
                    await this.promisedSetState({
                        preview: null,
                        loading_preview: true
                    });
                    let feed = JSON.parse(JSON.stringify(this.state.feed));
                    if (this.renders.customDisabled()) {
                        feed.custom = { id: 0, name: 'Disabled', value: 'disabled' };
                    }
                    if (feed.display_blur) {
                        feed.displayBlur = feed.display_blur;
                    }
                    if (feed.size_unit) {
                        feed.sizeUnit = feed.size_unit;
                    }
                    let response = await this.calls.createPreview({
                        listing: this.state.listing,
                        feeds: [feed]
                    });
                    await this.promisedSetState({
                        preview: response.data,
                        preview_params_missing: false
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_preview: false
            });
        },
        updateFeed: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let feed = JSON.parse(JSON.stringify(this.state.feed));
                await this.calls.updateFeed({
                    campaigns: feed.campaigns
                });
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    };

    renders = {
        customDisabled: () => {
            //TODO: add settings for project templates enterprise settings and hide unit templates settings if project
            if (this.state.user_enterprise && this.state.user_enterprise.name) {
                //UNIT
                if (this.state.feed.theme && this.state.feed.theme.value === "property_focus" && this.state.user_enterprise.customThemePropertyInFocus && this.state.user_enterprise.customThemePropertyInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "agency_focus" && this.state.user_enterprise.customThemeAgencyInFocus && this.state.user_enterprise.customThemeAgencyInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "broker_focus" && this.state.user_enterprise.customThemeBrokerInFocus && this.state.user_enterprise.customThemeBrokerInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "promo_focus" && this.state.user_enterprise.customThemePromoInFocus && this.state.user_enterprise.customThemePromoInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "basic_focus" && this.state.user_enterprise.customThemeBasicInFocus && this.state.user_enterprise.customThemeBasicInFocus.value === "enabled") {
                    return false;
                }
                //PROJECT
                else if (this.state.feed.theme && this.state.feed.theme.value === "foundation" && this.state.user_enterprise.customThemeFoundation && this.state.user_enterprise.customThemeFoundation.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "essence" && this.state.user_enterprise.customThemeEssence && this.state.user_enterprise.customThemeEssence.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "tonal" && this.state.user_enterprise.customThemeTonal && this.state.user_enterprise.customThemeTonal.value === "enabled") {
                    return false;
                } else {
                    return true;
                }
            } else if (this.state.feed.enterprise) {

            } else {
                return true;
            }
        },
        localize: (market, type, currency) => {

            //AVAILABLE MARKETS
            //TODO: add new markets here
            //TODO: some translations are done directly in form front-end, be sure to add support for new platform markets and langs also in front-end

            //console.log(market + " " + type + " " + currency);

            // Default values
            let currency_before = true;
            let base_language = "en";
            let base_currency = currency || "AED";
            let base_size_unit = "sqft";
            let base_dial_code = "971";

            // Market-specific overrides
            if (market === "ae" || market === "uae") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "AED";
                base_size_unit = "sqft";
                base_dial_code = "971";
            } else if (market === "se") {
                currency_before = false;
                base_language = "sv";
                base_currency = currency || "SEK";
                base_size_unit = "sqm";
                base_dial_code = "46";
            } else if (market === "gb") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "GBP";
                base_size_unit = "sqft";
                base_dial_code = "44";
            }

            switch (type) {
                case "base_language":
                    return base_language;
                case "base_currency":
                    return base_currency.toUpperCase();
                case "base_size_unit":
                    return base_size_unit;
                case "base_dial_code":
                    return base_dial_code;
            }
        },
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        fromAdmin: () => {
            return this.state.user && this.state.user.from_admin;
        },
        valid: () => {
            if (this.renders.basicUI()) {
                return this.state.feed.traffic && this.state.feed.traffic.value && this.state.feed.qualification && this.state.feed.qualification.value;
            } else {
                return this.state.feed.traffic && this.state.feed.traffic.value && this.state.feed.qualification && this.state.feed.qualification.value && this.state.feed.form;
            }
        },
        toLowerCase: (string) => {
            try {
                if (typeof string == "string") {
                    return string.toLowerCase();
                } else {
                    return string;
                }
            } catch (error) {

            }
        }
    };

    calls = {
        createFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed-light";
            return apiRegister.call(options, url);
        },
        createPreview: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed-light/creativeUrl";
            return apiRegister.call(options, url);
        },
        updateFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/feed-light/campaigns/" + this.state.feed.id;
            return apiRegister.call(options, url);
        },
        getEnterprises: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id + "&type=" + this.state.adbooster_type.id;
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings";
            return apiRegister.call(options, url);
        },
        getForms: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/form?language=" + this.state.feed.language.value + "&channel=" + (this.state.feed.channel ? this.state.feed.channel.value : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={this.state.feed.channel && this.state.feed.channel.value == "proptree" ? "w-screen max-w-4xl rounded-lg overflow-hidden" : "w-screen max-w-7xl rounded-lg overflow-hidden"}>
                                    <div className="h-full flex flex-row bg-white shadow-xl">
                                        {
                                            !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                            <div className="md:flex hidden flex-1 flex-col">
                                                <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                    <div className="flex items-center justify-between">
                                                        <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: "34px", zIndex: "99" }} className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                    <div className="col-span-12 sm:col-span-6 mb-4 md:lg-0">
                                                        <div className="space-y-6">
                                                            <div>
                                                                <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                    Listing
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={async (option) => {
                                                                            this.state.listing = option;
                                                                            await this.promisedSetState({
                                                                                listing: this.state.listing
                                                                            });
                                                                            this.functions.createPreview();
                                                                        }}
                                                                        loader={this.state.loading_listings}
                                                                        selected={this.state.listing ? this.state.listing : { id: 0, name: 'Click to Select' }}
                                                                        options={this.state.listings}
                                                                        name="feed-channel"
                                                                        id="feed-channel"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                    <div className="bg-gray-100 relative" style={{ width: "450px", height: "450px" }}>
                                                        {
                                                            this.state.preview_params_missing &&
                                                            <div className="w-full h-full text-sm flex items-center justify-center text-gray-700">
                                                                Select listing to see preview ...
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.preview_params_missing &&
                                                            //this.state.loading_preview &&
                                                            <div className="w-full h-full flex justify-center items-center flex-col">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                <div className="font-semibold text-sm mt-4 text-gray-700">Loading preview ...</div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.preview &&
                                                            <div className="absolute w-full h-full z-10 top-0">
                                                                <div className="w-full h-full relative">
                                                                    <iframe src={this.state.preview + "&legacy=false"} width="450" height="450" frameborder="0" scrolling="no" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.feed.id ? "Update Feed" : "New Feed"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 h-0 overflow-y-auto">
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col pt-6">
                                                        {
                                                            this.props.admin &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 divide-y divide-gray-200">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="enterprise-name" className="block text-sm font-medium text-gray-900">
                                                                                Enterprise
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.feed.enterprise = option;
                                                                                        this.setState({
                                                                                            feed: this.state.feed
                                                                                        })
                                                                                    }}
                                                                                    loader={this.state.loading_enterprise}
                                                                                    selected={this.state.feed.enterprise ? this.state.feed.enterprise : { id: 0, name: 'Select enterprise' }}
                                                                                    options={this.state.enterprises}
                                                                                    name="enterprise-name"
                                                                                    id="enterprise-name"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            !(this.state.feed.channel && this.state.feed.channel.value == 'proptree') &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 flex flex-row">
                                                                    <div className="space-y-6 pb-5 flex-1">
                                                                        <div onClick={() => {
                                                                            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                return navigator.clipboard.writeText(this.state.feed.filePath);
                                                                            }
                                                                        }}>
                                                                            <label htmlFor="feed-file-path" className="block text-sm font-medium text-gray-900">
                                                                                Feed URL (click to copy)
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={(event) => {

                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    value={this.state.feed.filePath ? this.state.feed.filePath : ""}
                                                                                    type="text"
                                                                                    name="feed-file-path"
                                                                                    id="feed-file-path"
                                                                                    className={"appearance-none block w-full h-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm" + (this.state.feed.id ? " bg-gray-100" : "")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            onClick={async () => {
                                                                                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                    navigator.clipboard.writeText(this.state.feed.filePath);
                                                                                    await this.promisedSetState({
                                                                                        copied: true
                                                                                    });
                                                                                    await this.functions.sleep(1000);
                                                                                    await this.promisedSetState({
                                                                                        copied: false
                                                                                    });
                                                                                }
                                                                            }}
                                                                            //style={window.innerWidth < 640 ? { height: "43px" } : {} }
                                                                            className={"cursor-pointer mt-6 ml-4 whitespace-no-wrap w-32 relative shadow-sm inline-flex h-10 justify-center items-center border px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                        >
                                                                            {
                                                                                !this.state.copied &&
                                                                                <div>Copy link</div>
                                                                            }
                                                                            {
                                                                                this.state.copied &&
                                                                                <div>Copied!</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            !this.renders.basicUI() &&
                                                            !(this.state.feed.channel && this.state.feed.channel.value == 'proptree') &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 divide-y divide-gray-200">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="report-id" className="block text-sm font-medium text-gray-900">
                                                                                Link campaign Id's to filter report on feed (use comma to separate if more than one)
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        this.state.feed.campaigns = event.target.value;
                                                                                        this.setState({
                                                                                            feed: this.state.feed
                                                                                        })
                                                                                    }}
                                                                                    value={this.state.feed.campaigns ? this.state.feed.campaigns : ""}
                                                                                    type="text"
                                                                                    name="report-id"
                                                                                    id="report-id"
                                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            Name
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.feed.name = event.target.value;
                                                                                    this.setState({
                                                                                        feed: this.state.feed
                                                                                    })
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                value={this.state.feed.name ? this.state.feed.name : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={(this.state.feed.id ? "bg-gray-100 " : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-6 mb-4 md:lg-0">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                            Channel
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.channel = option;
                                                                                    delete this.state.feed.theme;
                                                                                    delete this.state.feed.custom;
                                                                                    delete this.state.feed.extension;
                                                                                    delete this.state.feed.display_blur;
                                                                                    delete this.state.feed.displayBlur;
                                                                                    delete this.state.feed.type;
                                                                                    delete this.state.feed.language;
                                                                                    delete this.state.feed.currency;
                                                                                    delete this.state.feed.dial_code;
                                                                                    delete this.state.feed.size_unit;
                                                                                    delete this.state.feed.sizeUnit;
                                                                                    delete this.state.feed.property;
                                                                                    delete this.state.feed.form;
                                                                                    delete this.state.feed.traffic;
                                                                                    delete this.state.feed.qualification;
                                                                                    /*
                                                                                    if (option.value == "proptree") {
                                                                                        this.state.feed.type = { "id": 2, "name": "Sale", "value": "sale" };
                                                                                        this.state.feed.property = { "id": 3, "name": "Off-plan", "value": "off_plan" };
                                                                                        this.state.feed.custom = { "id": 0, "name": "Disabled", "value": "disabled" };
                                                                                        this.state.feed.dial_code = { "id": 18, "name": "UAE (+971)", "value": "971" };
                                                                                        this.state.feed.theme = { "id": 200, "value": "foundation", "name": "Foundation" };
                                                                                        this.state.feed.traffic = { "id": 3, "name": 'Proptree', "value": "proptree" };
                                                                                        this.state.feed.qualification = { "id": 3, "name": 'Unlock -> Form', "value": 'form_site' };
                                                                                    }
                                                                                    */
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.channel ? this.state.feed.channel : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'Meta', value: 'facebook' },
                                                                                    //...(this.state.user_enterprise &&
                                                                                    //    this.state.user_enterprise.managerUIproptree &&
                                                                                    //    this.state.user_enterprise.managerUIproptree.value === 'enabled' &&
                                                                                    //   this.renders.fromAdmin()
                                                                                    //   ? [{ id: 2, name: 'Proptree', value: 'proptree' }]
                                                                                    //   : []),
                                                                                    // Uncomment other options as needed
                                                                                    // { id: 3, name: 'Google', value: 'google' },
                                                                                    // { id: 4, name: 'Snapchat', value: 'snapchat' },
                                                                                    // { id: 5, name: 'TikTok', value: 'tiktok' },
                                                                                ]}
                                                                                name="feed-channel"
                                                                                id="feed-channel"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.feed.channel && this.state.feed.channel.value == "proptree" &&
                                                                <div className="col-span-12 sm:col-span-6 mb-4 md:lg-0 relative">
                                                                    {
                                                                        this.state.feed.id &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                                Set as fallback (used for agent proptree)
                                                                            </label>
                                                                            <div className="mt-2">
                                                                                <Switch
                                                                                    value={this.state.feed.proptreeDefault}
                                                                                    onSwitch={async () => {
                                                                                        this.state.feed.proptreeDefault = !this.state.feed.proptreeDefault;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {
                                                            !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    {
                                                                        !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="feed-theme" className="block text-sm font-medium text-gray-900">
                                                                                Theme
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                {
                                                                                    !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.theme = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            {
                                                                                                id: 1,
                                                                                                value: "property_focus",
                                                                                                name: "Property in focus"
                                                                                            },
                                                                                            {
                                                                                                id: 2,
                                                                                                value: "agency_focus",
                                                                                                name: "Agency in focus"
                                                                                            },
                                                                                            {
                                                                                                id: 3,
                                                                                                value: "broker_focus",
                                                                                                name: "Agent in focus"
                                                                                            },
                                                                                            {
                                                                                                id: 4,
                                                                                                value: "promo_focus",
                                                                                                name: "Promo in focus"
                                                                                            },
                                                                                            {
                                                                                                id: 5,
                                                                                                value: "basic_focus",
                                                                                                name: "Basic"
                                                                                            },
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "basic_focus";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="feed-theme"
                                                                                        id="feed-theme"
                                                                                    />
                                                                                }
                                                                                {
                                                                                    //TODO: add custom to this.renders.customDisabled()
                                                                                    this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.theme = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            {
                                                                                                id: 200,
                                                                                                value: "foundation",
                                                                                                name: "Foundation"
                                                                                            },
                                                                                            {
                                                                                                id: 201,
                                                                                                value: "essence",
                                                                                                name: "Essence"
                                                                                            },
                                                                                            {
                                                                                                id: 202,
                                                                                                value: "tonal",
                                                                                                name: "Tonal"
                                                                                            },
                                                                                            /*
                                                                                            {
                                                                                                id: 203,
                                                                                                value: "mixed",
                                                                                                name: "Randomize"
                                                                                            }
                                                                                            */
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "foundation";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="theme"
                                                                                        id="theme"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    {
                                                                        !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className={this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 ? "space-y-6" : "space-y-6 pb-5"}>
                                                                        <div>
                                                                            <label htmlFor="feed-custom" className="block text-sm font-medium text-gray-900">
                                                                                Custom
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.custom = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id || this.renders.customDisabled()}
                                                                                    selected={this.renders.customDisabled() ? { id: 0, name: 'Disabled', value: 'disabled' } : this.state.feed.custom ? this.state.feed.custom : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                    options={[
                                                                                        { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                        { id: 1, name: 'Enabled', value: 'enabled' }
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "disabled";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-custom"
                                                                                    id="feed-custom"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        {
                                                                            !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                            <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <div className={this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 ? "space-y-6" : "space-y-6 pb-5"}>
                                                                            <div>
                                                                                <label htmlFor="feed-extension" className="block text-sm font-medium text-gray-900">
                                                                                    Extension
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.extension = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={(this.state.feed.channel && this.state.feed.channel.value == "proptree") || this.state.feed.id || (this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed")}
                                                                                        selected={this.state.feed.extension ? this.state.feed.extension : this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed" ? { id: 0, name: 'Mixed', value: 'disabled' } : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                        options={[
                                                                                            { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                            { id: 1, name: 'Agent', value: 'agent' }
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "disabled";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="feed-extension"
                                                                                        id="feed-extension"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        {
                                                                            !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                            <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <div className="space-y-6 pb-5">
                                                                            <div>
                                                                                <label htmlFor="feed-blur" className="block text-sm font-medium text-gray-900">
                                                                                    Blur
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.display_blur = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={(this.state.feed.channel && this.state.feed.channel.value == "proptree") || this.state.feed.id || (this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed")}
                                                                                        selected={this.state.feed.display_blur ? this.state.feed.display_blur : this.state.feed.displayBlur ? this.state.feed.displayBlur : this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed" ? { id: 0, name: 'Mixed', value: 'disabled' } : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                        options={[
                                                                                            { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                            { id: 1, name: 'Enabled', value: 'enabled' }
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "disabled";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="feed-blur"
                                                                                        id="feed-blur"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            {
                                                                !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                                <div className="col-span-12 sm:col-span-4 relative">
                                                                    {
                                                                        !(this.state.feed.theme && this.state.feed.theme.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 sm:pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                                Type
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                {
                                                                                    !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.type = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.type ? this.state.feed.type : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            { id: 1, name: 'Sale & Rentals', value: "both" },
                                                                                            { id: 2, name: 'Sale', value: "sale" },
                                                                                            { id: 3, name: 'Rentals', value: "rent" }
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "both";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="feed-type"
                                                                                        id="feed-type"
                                                                                    />
                                                                                }
                                                                                {
                                                                                    this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.type = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.type ? this.state.feed.type : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            { id: 2, name: 'Sale', value: "sale" }
                                                                                        ]}
                                                                                        name="feed-type"
                                                                                        id="feed-type"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.type && this.state.feed.type.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-lang" className="block text-sm font-medium text-gray-900">
                                                                            Language
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.language = option;
                                                                                    delete this.state.feed.form;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.getForms();
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.language ? this.state.feed.language : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'English', value: "en" },
                                                                                    { id: 2, name: 'Swedish', value: "sv" },
                                                                                    { id: 3, name: 'Arabic', value: "ar" },
                                                                                    { id: 4, name: 'German', value: "de" },
                                                                                    { id: 5, name: 'Spanish', value: "es" },
                                                                                    { id: 6, name: 'French', value: "fr" },
                                                                                    { id: 7, name: 'Russian', value: "ru" },
                                                                                    { id: 8, name: 'Dutch', value: "nl" },
                                                                                    { id: 9, name: 'Portuguese', value: "pt" },
                                                                                    { id: 10, name: 'Italian', value: "it" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.value === this.state.base_language) return -1;
                                                                                    if (b.value === this.state.base_language) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.state.base_language;
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-lang"
                                                                                id="feed-lang"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.language && this.state.feed.language.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-currency" className="block text-sm font-medium text-gray-900">
                                                                            Currency
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.currency = option;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.currency ? this.state.feed.currency : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'USD', value: "usd" },
                                                                                    { id: 2, name: 'SEK', value: "sek" },
                                                                                    { id: 3, name: 'AED', value: "aed" },
                                                                                    { id: 4, name: 'EUR', value: "eur" },
                                                                                    { id: 5, name: 'GBP', value: "gbp" },
                                                                                    { id: 6, name: 'ILS', value: "ils" },
                                                                                    { id: 7, name: 'AUD', value: "aud" },
                                                                                    { id: 8, name: 'CHF', value: "chf" },
                                                                                    { id: 9, name: 'ZAR', value: "zar" },
                                                                                    { id: 10, name: 'NZD', value: "nzd" },
                                                                                    { id: 11, name: 'INR', value: "inr" },
                                                                                    { id: 12, name: 'CAD', value: "cad" },
                                                                                    { id: 13, name: 'NOK', value: "nok" },
                                                                                    { id: 14, name: 'DKK', value: "dkk" },
                                                                                    { id: 15, name: 'SAR', value: "sar" },
                                                                                    { id: 16, name: 'QAR', value: "qar" },
                                                                                    { id: 17, name: 'ADA', value: "ada" },
                                                                                    { id: 18, name: 'AFN', value: "afn" },
                                                                                    { id: 19, name: 'ALL', value: "all" },
                                                                                    { id: 20, name: 'AMD', value: "amd" },
                                                                                    { id: 21, name: 'ANG', value: "ang" },
                                                                                    { id: 22, name: 'AOA', value: "aoa" },
                                                                                    { id: 23, name: 'ARB', value: "arb" },
                                                                                    { id: 24, name: 'ARS', value: "ars" },
                                                                                    { id: 25, name: 'AVAX', value: "avax" },
                                                                                    { id: 26, name: 'AWG', value: "awg" },
                                                                                    { id: 27, name: 'AZN', value: "azn" },
                                                                                    { id: 28, name: 'BAM', value: "bam" },
                                                                                    { id: 29, name: 'BBD', value: "bbd" },
                                                                                    { id: 30, name: 'BDT', value: "bdt" },
                                                                                    { id: 31, name: 'BGN', value: "bgn" },
                                                                                    { id: 32, name: 'BHD', value: "bhd" },
                                                                                    { id: 33, name: 'BIF', value: "bif" },
                                                                                    { id: 34, name: 'BMD', value: "bmd" },
                                                                                    { id: 35, name: 'BNB', value: "bnb" },
                                                                                    { id: 36, name: 'BND', value: "bnd" },
                                                                                    { id: 37, name: 'BOB', value: "bob" },
                                                                                    { id: 38, name: 'BRL', value: "brl" },
                                                                                    { id: 39, name: 'BSD', value: "bsd" },
                                                                                    { id: 40, name: 'BTC', value: "btc" },
                                                                                    { id: 41, name: 'BTN', value: "btn" },
                                                                                    { id: 42, name: 'BWP', value: "bwp" },
                                                                                    { id: 43, name: 'BYN', value: "byn" },
                                                                                    { id: 44, name: 'BYR', value: "byr" },
                                                                                    { id: 45, name: 'BZD', value: "bzd" },
                                                                                    { id: 46, name: 'CDF', value: "cdf" },
                                                                                    { id: 47, name: 'CLF', value: "clf" },
                                                                                    { id: 48, name: 'CLP', value: "clp" },
                                                                                    { id: 49, name: 'CNY', value: "cny" },
                                                                                    { id: 50, name: 'COP', value: "cop" },
                                                                                    { id: 51, name: 'CRC', value: "crc" },
                                                                                    { id: 52, name: 'CUC', value: "cuc" },
                                                                                    { id: 53, name: 'CUP', value: "cup" },
                                                                                    { id: 54, name: 'CVE', value: "cve" },
                                                                                    { id: 55, name: 'CZK', value: "czk" },
                                                                                    { id: 56, name: 'DAI', value: "dai" },
                                                                                    { id: 57, name: 'DJF', value: "djf" },
                                                                                    { id: 58, name: 'DOP', value: "dop" },
                                                                                    { id: 59, name: 'DOT', value: "dot" },
                                                                                    { id: 60, name: 'DZD', value: "dzd" },
                                                                                    { id: 61, name: 'EGP', value: "egp" },
                                                                                    { id: 62, name: 'ERN', value: "ern" },
                                                                                    { id: 63, name: 'ETB', value: "etb" },
                                                                                    { id: 64, name: 'ETH', value: "eth" },
                                                                                    { id: 65, name: 'FJD', value: "fjd" },
                                                                                    { id: 66, name: 'FKP', value: "fkp" },
                                                                                    { id: 67, name: 'GEL', value: "gel" },
                                                                                    { id: 68, name: 'GGP', value: "ggp" },
                                                                                    { id: 69, name: 'GHS', value: "ghs" },
                                                                                    { id: 70, name: 'GIP', value: "gip" },
                                                                                    { id: 71, name: 'GMD', value: "gmd" },
                                                                                    { id: 72, name: 'GNF', value: "gnf" },
                                                                                    { id: 73, name: 'GTQ', value: "gtq" },
                                                                                    { id: 74, name: 'GYD', value: "gyd" },
                                                                                    { id: 75, name: 'HKD', value: "hkd" },
                                                                                    { id: 76, name: 'HNL', value: "hnl" },
                                                                                    { id: 77, name: 'HRK', value: "hrk" },
                                                                                    { id: 78, name: 'HTG', value: "htg" },
                                                                                    { id: 79, name: 'HUF', value: "huf" },
                                                                                    { id: 80, name: 'IDR', value: "idr" },
                                                                                    { id: 81, name: 'IMP', value: "imp" },
                                                                                    { id: 82, name: 'IQD', value: "iqd" },
                                                                                    { id: 83, name: 'IRR', value: "irr" },
                                                                                    { id: 84, name: 'ISK', value: "isk" },
                                                                                    { id: 85, name: 'JEP', value: "jep" },
                                                                                    { id: 86, name: 'JMD', value: "jmd" },
                                                                                    { id: 87, name: 'JOD', value: "jod" },
                                                                                    { id: 88, name: 'JPY', value: "jpy" },
                                                                                    { id: 89, name: 'KES', value: "kes" },
                                                                                    { id: 90, name: 'KGS', value: "kgs" },
                                                                                    { id: 91, name: 'KHR', value: "khr" },
                                                                                    { id: 92, name: 'KMF', value: "kmf" },
                                                                                    { id: 93, name: 'KPW', value: "kpw" },
                                                                                    { id: 94, name: 'KRW', value: "krw" },
                                                                                    { id: 95, name: 'KWD', value: "kwd" },
                                                                                    { id: 96, name: 'KYD', value: "kyd" },
                                                                                    { id: 97, name: 'KZT', value: "kzt" },
                                                                                    { id: 98, name: 'LAK', value: "lak" },
                                                                                    { id: 99, name: 'LBP', value: "lbp" },
                                                                                    { id: 100, name: 'LKR', value: "lkr" },
                                                                                    { id: 101, name: 'LRD', value: "lrd" },
                                                                                    { id: 102, name: 'LSL', value: "lsl" },
                                                                                    { id: 103, name: 'LTC', value: "ltc" },
                                                                                    { id: 104, name: 'LTL', value: "ltl" },
                                                                                    { id: 105, name: 'LVL', value: "lvl" },
                                                                                    { id: 106, name: 'LYD', value: "lyd" },
                                                                                    { id: 107, name: 'MAD', value: "mad" },
                                                                                    { id: 108, name: 'MATIC', value: "matic" },
                                                                                    { id: 109, name: 'MDL', value: "mdl" },
                                                                                    { id: 110, name: 'MGA', value: "mga" },
                                                                                    { id: 111, name: 'MKD', value: "mkd" },
                                                                                    { id: 112, name: 'MMK', value: "mmk" },
                                                                                    { id: 113, name: 'MNT', value: "mnt" },
                                                                                    { id: 114, name: 'MOP', value: "mop" },
                                                                                    { id: 115, name: 'MRO', value: "mro" },
                                                                                    { id: 116, name: 'MRU', value: "mru" },
                                                                                    { id: 117, name: 'MUR', value: "mur" },
                                                                                    { id: 118, name: 'MVR', value: "mvr" },
                                                                                    { id: 119, name: 'MWK', value: "mwk" },
                                                                                    { id: 120, name: 'MXN', value: "mxn" },
                                                                                    { id: 121, name: 'MYR', value: "myr" },
                                                                                    { id: 122, name: 'MZN', value: "mzn" },
                                                                                    { id: 123, name: 'NAD', value: "nad" },
                                                                                    { id: 124, name: 'NGN', value: "ngn" },
                                                                                    { id: 125, name: 'NIO', value: "nio" },
                                                                                    { id: 126, name: 'OMR', value: "omr" },
                                                                                    { id: 127, name: 'OP', value: "op" },
                                                                                    { id: 128, name: 'PAB', value: "pab" },
                                                                                    { id: 129, name: 'PEN', value: "pen" },
                                                                                    { id: 130, name: 'PGK', value: "pgk" },
                                                                                    { id: 131, name: 'PHP', value: "php" },
                                                                                    { id: 132, name: 'PKR', value: "pkr" },
                                                                                    { id: 133, name: 'PLN', value: "pln" },
                                                                                    { id: 134, name: 'PYG', value: "pyg" },
                                                                                    { id: 135, name: 'RON', value: "ron" },
                                                                                    { id: 136, name: 'RSD', value: "rsd" },
                                                                                    { id: 137, name: 'RUB', value: "rub" },
                                                                                    { id: 138, name: 'RWF', value: "rwf" },
                                                                                    { id: 139, name: 'SBD', value: "sbd" },
                                                                                    { id: 140, name: 'SCR', value: "scr" },
                                                                                    { id: 141, name: 'SDG', value: "sdg" },
                                                                                    { id: 142, name: 'SGD', value: "sgd" },
                                                                                    { id: 143, name: 'SHP', value: "shp" },
                                                                                    { id: 144, name: 'SLL', value: "sll" },
                                                                                    { id: 145, name: 'SOL', value: "sol" },
                                                                                    { id: 146, name: 'SRD', value: "srd" },
                                                                                    { id: 147, name: 'STD', value: "std" },
                                                                                    { id: 148, name: 'STN', value: "stn" },
                                                                                    { id: 149, name: 'SVC', value: "svc" },
                                                                                    { id: 150, name: 'SYP', value: "syp" },
                                                                                    { id: 151, name: 'SZL', value: "szl" },
                                                                                    { id: 152, name: 'THB', value: "thb" },
                                                                                    { id: 153, name: 'TJS', value: "tjs" },
                                                                                    { id: 154, name: 'TMT', value: "tmt" },
                                                                                    { id: 155, name: 'TND', value: "tnd" },
                                                                                    { id: 156, name: 'TOP', value: "top" },
                                                                                    { id: 157, name: 'TRY', value: "try" },
                                                                                    { id: 158, name: 'TTD', value: "ttd" },
                                                                                    { id: 159, name: 'TWD', value: "twd" },
                                                                                    { id: 160, name: 'TZS', value: "tzs" },
                                                                                    { id: 161, name: 'UAH', value: "uah" },
                                                                                    { id: 162, name: 'UGX', value: "ugx" },
                                                                                    { id: 163, name: 'USDC', value: "usdc" },
                                                                                    { id: 164, name: 'USDT', value: "usdt" },
                                                                                    { id: 165, name: 'UYU', value: "uyu" },
                                                                                    { id: 166, name: 'UZS', value: "uzs" },
                                                                                    { id: 167, name: 'VEF', value: "vef" },
                                                                                    { id: 168, name: 'VES', value: "ves" },
                                                                                    { id: 169, name: 'VND', value: "vnd" },
                                                                                    { id: 170, name: 'VUV', value: "vuv" },
                                                                                    { id: 171, name: 'WST', value: "wst" },
                                                                                    { id: 172, name: 'XAF', value: "xaf" },
                                                                                    { id: 173, name: 'XAG', value: "xag" },
                                                                                    { id: 174, name: 'XAU', value: "xau" },
                                                                                    { id: 175, name: 'XCD', value: "xcd" },
                                                                                    { id: 176, name: 'XDR', value: "xdr" },
                                                                                    { id: 177, name: 'XOF', value: "xof" },
                                                                                    { id: 178, name: 'XPD', value: "xpd" },
                                                                                    { id: 179, name: 'XPF', value: "xpf" },
                                                                                    { id: 180, name: 'XPT', value: "xpt" },
                                                                                    { id: 181, name: 'XRP', value: "xrp" },
                                                                                    { id: 182, name: 'YER', value: "yer" },
                                                                                    { id: 183, name: 'ZMW', value: "zmw" },
                                                                                    { id: 184, name: 'ZWL', value: "zwl" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.value === this.renders.toLowerCase(this.state.base_currency)) return -1;
                                                                                    if (b.value === this.renders.toLowerCase(this.state.base_currency)) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.renders.toLowerCase(this.state.base_currency) || item.value == "usd";
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-currency"
                                                                                id="feed-currency"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 sm:col-span-4 relative">
                                                                    {
                                                                        !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 lg:pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-dial-code" className="block text-sm font-medium text-gray-900">
                                                                                Country code
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    noLimit={true}
                                                                                    select={async (option) => {
                                                                                        this.state.feed.dial_code = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.dial_code ? this.state.feed.dial_code : { id: 1, name: 'None', value: "" }}
                                                                                    options={[
                                                                                        { id: 1, name: 'None', value: "" },
                                                                                        { id: 2, name: 'Denmark (+45)', value: "45" },
                                                                                        { id: 3, name: 'France (+33)', value: "33" },
                                                                                        { id: 4, name: 'Germany (+49)', value: "49" },
                                                                                        { id: 5, name: 'Ireland (+353)', value: "353" },
                                                                                        { id: 6, name: 'Norway (+47)', value: "47" },
                                                                                        { id: 7, name: 'Sweden (+46)', value: "46" },
                                                                                        { id: 8, name: 'Switzerland (+41)', value: "41" },
                                                                                        { id: 9, name: 'UK (+44)', value: "44" },
                                                                                        { id: 10, name: 'Australia (+61)', value: "61" },
                                                                                        { id: 11, name: 'Canada/US (+1)', value: "1" },
                                                                                        { id: 12, name: 'India (+91)', value: "91" },
                                                                                        { id: 13, name: 'Israel (+972)', value: "972" },
                                                                                        { id: 14, name: 'New Zealand (+64)', value: "64" },
                                                                                        { id: 15, name: 'Qatar (+974)', value: "974" },
                                                                                        { id: 16, name: 'Saudi Arabia (+966)', value: "966" },
                                                                                        { id: 17, name: 'South Africa (+27)', value: "27" },
                                                                                        { id: 18, name: 'UAE (+971)', value: "971" },
                                                                                        { id: 19, name: 'Spain (+34)', value: "34" },
                                                                                        { id: 20, name: 'Portugal (+351)', value: "351" },
                                                                                        { id: 21, name: 'Netherlands (+31)', value: "31" },
                                                                                        { id: 22, name: 'Italy (+39)', value: "39" },
                                                                                        { id: 23, name: 'Afghanistan (+93)', value: "93" },
                                                                                        { id: 24, name: 'Albania (+355)', value: "355" },
                                                                                        { id: 25, name: 'Algeria (+213)', value: "213" },
                                                                                        { id: 26, name: 'American Samoa (+1684)', value: "1684" },
                                                                                        { id: 27, name: 'Andorra (+376)', value: "376" },
                                                                                        { id: 28, name: 'Angola (+244)', value: "244" },
                                                                                        { id: 29, name: 'Antigua & Barbuda (+1268)', value: "1268" },
                                                                                        { id: 30, name: 'Argentina (+54)', value: "54" },
                                                                                        { id: 31, name: 'Armenia (+374)', value: "374" },
                                                                                        { id: 32, name: 'Austria (+43)', value: "43" },
                                                                                        { id: 33, name: 'Azerbaijan (+994)', value: "994" },
                                                                                        { id: 34, name: 'Bahamas (+1242)', value: "1242" },
                                                                                        { id: 35, name: 'Bahrain (+973)', value: "973" },
                                                                                        { id: 36, name: 'Bangladesh (+880)', value: "880" },
                                                                                        { id: 37, name: 'Barbados (+1246)', value: "1246" },
                                                                                        { id: 38, name: 'Belarus (+375)', value: "375" },
                                                                                        { id: 39, name: 'Belgium (+32)', value: "32" },
                                                                                        { id: 40, name: 'Belize (+501)', value: "501" },
                                                                                        { id: 41, name: 'Benin (+229)', value: "229" },
                                                                                        { id: 42, name: 'Bhutan (+975)', value: "975" },
                                                                                        { id: 43, name: 'Bolivia (+591)', value: "591" },
                                                                                        { id: 44, name: 'Bosnia & Herzegovina (+387)', value: "387" },
                                                                                        { id: 45, name: 'Botswana (+267)', value: "267" },
                                                                                        { id: 46, name: 'Brazil (+55)', value: "55" },
                                                                                        { id: 47, name: 'Brunei (+673)', value: "673" },
                                                                                        { id: 48, name: 'Bulgaria (+359)', value: "359" },
                                                                                        { id: 49, name: 'Burkina Faso (+226)', value: "226" },
                                                                                        { id: 50, name: 'Burundi (+257)', value: "257" },
                                                                                        { id: 51, name: 'Cabo Verde (+238)', value: "238" },
                                                                                        { id: 52, name: 'Cambodia (+855)', value: "855" },
                                                                                        { id: 53, name: 'Cameroon (+237)', value: "237" },
                                                                                        { id: 54, name: 'Central African Republic (+236)', value: "236" },
                                                                                        { id: 55, name: 'Chad (+235)', value: "235" },
                                                                                        { id: 56, name: 'Chile (+56)', value: "56" },
                                                                                        { id: 57, name: 'China (+86)', value: "86" },
                                                                                        { id: 58, name: 'Colombia (+57)', value: "57" },
                                                                                        { id: 59, name: 'Comoros (+269)', value: "269" },
                                                                                        { id: 60, name: 'Congo (+242)', value: "242" },
                                                                                        { id: 61, name: 'Costa Rica (+506)', value: "506" },
                                                                                        { id: 62, name: 'Croatia (+385)', value: "385" },
                                                                                        { id: 63, name: 'Cuba (+53)', value: "53" },
                                                                                        { id: 64, name: 'Cyprus (+357)', value: "357" },
                                                                                        { id: 65, name: 'Czech Republic (+420)', value: "420" },
                                                                                        { id: 66, name: 'Djibouti (+253)', value: "253" },
                                                                                        { id: 67, name: 'Dominica (+1767)', value: "1767" },
                                                                                        { id: 68, name: 'Dominican Republic (+1809)', value: "1809" },
                                                                                        { id: 69, name: 'Ecuador (+593)', value: "593" },
                                                                                        { id: 70, name: 'Egypt (+20)', value: "20" },
                                                                                        { id: 71, name: 'El Salvador (+503)', value: "503" },
                                                                                        { id: 72, name: 'Equatorial Guinea (+240)', value: "240" },
                                                                                        { id: 73, name: 'Eritrea (+291)', value: "291" },
                                                                                        { id: 74, name: 'Estonia (+372)', value: "372" },
                                                                                        { id: 75, name: 'Eswatini (+268)', value: "268" },
                                                                                        { id: 76, name: 'Ethiopia (+251)', value: "251" },
                                                                                        { id: 77, name: 'Fiji (+679)', value: "679" },
                                                                                        { id: 78, name: 'Finland (+358)', value: "358" },
                                                                                        { id: 79, name: 'Gabon (+241)', value: "241" },
                                                                                        { id: 80, name: 'Gambia (+220)', value: "220" },
                                                                                        { id: 81, name: 'Georgia (+995)', value: "995" },
                                                                                        { id: 82, name: 'Ghana (+233)', value: "233" },
                                                                                        { id: 83, name: 'Greece (+30)', value: "30" },
                                                                                        { id: 84, name: 'Grenada (+1473)', value: "1473" },
                                                                                        { id: 85, name: 'Guatemala (+502)', value: "502" },
                                                                                        { id: 86, name: 'Guinea (+224)', value: "224" },
                                                                                        { id: 87, name: 'Guinea-Bissau (+245)', value: "245" },
                                                                                        { id: 88, name: 'Guyana (+592)', value: "592" },
                                                                                        { id: 89, name: 'Haiti (+509)', value: "509" },
                                                                                        { id: 90, name: 'Honduras (+504)', value: "504" },
                                                                                        { id: 195, name: 'Hong Kong (+852)', value: "852" },
                                                                                        { id: 91, name: 'Hungary (+36)', value: "36" },
                                                                                        { id: 92, name: 'Iceland (+354)', value: "354" },
                                                                                        { id: 93, name: 'Indonesia (+62)', value: "62" },
                                                                                        { id: 94, name: 'Iran (+98)', value: "98" },
                                                                                        { id: 95, name: 'Iraq (+964)', value: "964" },
                                                                                        { id: 98, name: 'Jamaica (+1876)', value: "1876" },
                                                                                        { id: 99, name: 'Japan (+81)', value: "81" },
                                                                                        { id: 100, name: 'Jordan (+962)', value: "962" },
                                                                                        { id: 101, name: 'Kazakhstan (+7)', value: "7" },
                                                                                        { id: 102, name: 'Kenya (+254)', value: "254" },
                                                                                        { id: 103, name: 'Kiribati (+686)', value: "686" },
                                                                                        //{ id: 104, name: 'South Korea (+82)', value: "82" },
                                                                                        { id: 105, name: 'Kuwait (+965)', value: "965" },
                                                                                        { id: 106, name: 'Kyrgyzstan (+996)', value: "996" },
                                                                                        { id: 107, name: 'Laos (+856)', value: "856" },
                                                                                        { id: 108, name: 'Latvia (+371)', value: "371" },
                                                                                        { id: 109, name: 'Lebanon (+961)', value: "961" },
                                                                                        { id: 110, name: 'Lesotho (+266)', value: "266" },
                                                                                        { id: 111, name: 'Liberia (+231)', value: "231" },
                                                                                        { id: 112, name: 'Libya (+218)', value: "218" },
                                                                                        { id: 113, name: 'Liechtenstein (+423)', value: "423" },
                                                                                        { id: 114, name: 'Lithuania (+370)', value: "370" },
                                                                                        { id: 115, name: 'Luxembourg (+352)', value: "352" },
                                                                                        { id: 116, name: 'Madagascar (+261)', value: "261" },
                                                                                        { id: 117, name: 'Malawi (+265)', value: "265" },
                                                                                        { id: 118, name: 'Malaysia (+60)', value: "60" },
                                                                                        { id: 119, name: 'Maldives (+960)', value: "960" },
                                                                                        { id: 120, name: 'Mali (+223)', value: "223" },
                                                                                        { id: 121, name: 'Malta (+356)', value: "356" },
                                                                                        { id: 122, name: 'Marshall Islands (+692)', value: "692" },
                                                                                        { id: 123, name: 'Mauritania (+222)', value: "222" },
                                                                                        { id: 124, name: 'Mauritius (+230)', value: "230" },
                                                                                        { id: 125, name: 'Mexico (+52)', value: "52" },
                                                                                        { id: 126, name: 'Micronesia (+691)', value: "691" },
                                                                                        { id: 127, name: 'Moldova (+373)', value: "373" },
                                                                                        { id: 128, name: 'Monaco (+377)', value: "377" },
                                                                                        { id: 129, name: 'Mongolia (+976)', value: "976" },
                                                                                        { id: 130, name: 'Montenegro (+382)', value: "382" },
                                                                                        { id: 131, name: 'Morocco (+212)', value: "212" },
                                                                                        { id: 132, name: 'Mozambique (+258)', value: "258" },
                                                                                        { id: 133, name: 'Myanmar (+95)', value: "95" },
                                                                                        { id: 134, name: 'Namibia (+264)', value: "264" },
                                                                                        { id: 135, name: 'Nauru (+674)', value: "674" },
                                                                                        { id: 136, name: 'Nepal (+977)', value: "977" },
                                                                                        { id: 137, name: 'Nicaragua (+505)', value: "505" },
                                                                                        { id: 138, name: 'Niger (+227)', value: "227" },
                                                                                        { id: 139, name: 'Nigeria (+234)', value: "234" },
                                                                                        { id: 140, name: 'North Macedonia (+389)', value: "389" },
                                                                                        { id: 142, name: 'Oman (+968)', value: "968" },
                                                                                        { id: 143, name: 'Pakistan (+92)', value: "92" },
                                                                                        { id: 144, name: 'Palau (+680)', value: "680" },
                                                                                        { id: 145, name: 'Panama (+507)', value: "507" },
                                                                                        { id: 146, name: 'Papua New Guinea (+675)', value: "675" },
                                                                                        { id: 147, name: 'Paraguay (+595)', value: "595" },
                                                                                        { id: 148, name: 'Peru (+51)', value: "51" },
                                                                                        { id: 149, name: 'Philippines (+63)', value: "63" },
                                                                                        { id: 150, name: 'Poland (+48)', value: "48" },
                                                                                        { id: 151, name: 'Romania (+40)', value: "40" },
                                                                                        { id: 152, name: 'Russia (+7)', value: "7" },
                                                                                        { id: 153, name: 'Rwanda (+250)', value: "250" },
                                                                                        { id: 154, name: 'Saint Kitts & Nevis (+1869)', value: "1869" },
                                                                                        { id: 155, name: 'Saint Lucia (+1758)', value: "1758" },
                                                                                        { id: 156, name: 'Saint Vincent & Grenadines (+1784)', value: "1784" },
                                                                                        { id: 157, name: 'Samoa (+685)', value: "685" },
                                                                                        { id: 158, name: 'San Marino (+378)', value: "378" },
                                                                                        { id: 159, name: 'Sao Tome & Principe (+239)', value: "239" },
                                                                                        { id: 160, name: 'Senegal (+221)', value: "221" },
                                                                                        { id: 161, name: 'Serbia (+381)', value: "381" },
                                                                                        { id: 162, name: 'Seychelles (+248)', value: "248" },
                                                                                        { id: 163, name: 'Sierra Leone (+232)', value: "232" },
                                                                                        { id: 164, name: 'Singapore (+65)', value: "65" },
                                                                                        { id: 165, name: 'Slovakia (+421)', value: "421" },
                                                                                        { id: 166, name: 'Slovenia (+386)', value: "386" },
                                                                                        { id: 167, name: 'Solomon Islands (+677)', value: "677" },
                                                                                        { id: 168, name: 'South Korea (+82)', value: "82" },
                                                                                        { id: 169, name: 'South Sudan (+211)', value: "211" },
                                                                                        { id: 170, name: 'Sri Lanka (+94)', value: "94" },
                                                                                        { id: 171, name: 'Sudan (+249)', value: "249" },
                                                                                        { id: 173, name: 'Syria (+963)', value: "963" },
                                                                                        { id: 174, name: 'Taiwan (+886)', value: "886" },
                                                                                        { id: 175, name: 'Tajikistan (+992)', value: "992" },
                                                                                        { id: 176, name: 'Tanzania (+255)', value: "255" },
                                                                                        { id: 177, name: 'Thailand (+66)', value: "66" },
                                                                                        { id: 178, name: 'Togo (+228)', value: "228" },
                                                                                        { id: 179, name: 'Trinidad & Tobago (+1868)', value: "1868" },
                                                                                        { id: 180, name: 'Tunisia (+216)', value: "216" },
                                                                                        { id: 181, name: 'Turkey (+90)', value: "90" },
                                                                                        { id: 182, name: 'Turkmenistan (+993)', value: "993" },
                                                                                        { id: 183, name: 'Tuvalu (+688)', value: "688" },
                                                                                        { id: 184, name: 'Uganda (+256)', value: "256" },
                                                                                        { id: 185, name: 'Ukraine (+380)', value: "380" },
                                                                                        { id: 186, name: 'Uruguay (+598)', value: "598" },
                                                                                        { id: 187, name: 'Uzbekistan (+998)', value: "998" },
                                                                                        { id: 188, name: 'Vanuatu (+678)', value: "678" },
                                                                                        { id: 189, name: 'Vatican City (+379)', value: "379" },
                                                                                        { id: 190, name: 'Venezuela (+58)', value: "58" },
                                                                                        { id: 191, name: 'Vietnam (+84)', value: "84" },
                                                                                        { id: 192, name: 'Yemen (+967)', value: "967" },
                                                                                        { id: 193, name: 'Zambia (+260)', value: "260" },
                                                                                        { id: 194, name: 'Zimbabwe (+263)', value: "263" }
                                                                                    ].sort((a, b) => {
                                                                                        if (a.name === 'None') return -1;
                                                                                        if (b.name === 'None') return 1;
                                                                                        if (a.value === this.state.base_dial_code) return -1;
                                                                                        if (b.value === this.state.base_dial_code) return 1;
                                                                                        return a.name.localeCompare(b.name);
                                                                                    }).filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == this.state.base_dial_code;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-dial-code"
                                                                                    id="feed-dial-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-4 relative">
                                                                    {
                                                                        (this.state.feed.dial_code && this.state.feed.dial_code.value !== "") || !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-size-unit" className="block text-sm font-medium text-gray-900">
                                                                                Size unit
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.size_unit = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.size_unit ? this.state.feed.size_unit : this.state.feed.sizeUnit ? this.state.feed.sizeUnit : this.state.base_size_unit === "sqft" ? { id: 1, name: "SQFT", value: "sqft" } : { id: 2, name: 'SQM', value: "sqm" }}
                                                                                    options={[
                                                                                        { id: 1, name: "SQFT", value: "sqft" },
                                                                                        { id: 2, name: 'SQM', value: "sqm" }
                                                                                    ].sort((a, b) => {
                                                                                        if (a.value === this.state.base_size_unit) return -1;
                                                                                        if (b.value === this.state.base_size_unit) return 1;
                                                                                        return a.name.localeCompare(b.name);
                                                                                    }).filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == this.state.base_size_unit;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-size-unit"
                                                                                    id="feed-size-unit"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={!(this.state.feed.channel && this.state.feed.channel.value == "proptree") ? "sm:col-span-4 col-span-12 relative -mt-5 sm:mt-0" : "sm:col-span-4 col-span-12 relative -mt-5 sm:mt-0"}>
                                                                    {
                                                                        !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-plan" className="block text-sm font-medium text-gray-900">
                                                                                Plan
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                {
                                                                                    !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.property = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.property ? this.state.feed.property : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                                            { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                                            { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                        ].filter((item) => {
                                                                                            if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                                return item.value == "both";
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        })}
                                                                                        name="feed-plan"
                                                                                        id="feed-plan"
                                                                                    />
                                                                                }
                                                                                {
                                                                                    this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            this.state.feed.property = option;
                                                                                            await this.promisedSetState({
                                                                                                feed: this.state.feed
                                                                                            });
                                                                                            this.functions.createPreview();
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.property ? this.state.feed.property : { id: 0, name: 'Click to Select' }}
                                                                                        options={[
                                                                                            { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                        ]}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            {
                                                                this.state.feed.channel && this.state.feed.channel.value == "proptree" &&
                                                                <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                    {
                                                                        !(this.state.feed.property && this.state.feed.property.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-form" className="block text-sm font-medium text-gray-900">
                                                                                Form
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.feed.form = option;
                                                                                        this.setState({
                                                                                            feed: this.state.feed
                                                                                        })
                                                                                    }}
                                                                                    showTag={true}
                                                                                    loader={this.state.loading_forms}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.form ? this.state.feed.form : { id: 0, name: 'Select form', value: "none" }}
                                                                                    options={this.state.forms
                                                                                        .map((item) => {
                                                                                            return {
                                                                                                name: item.name,
                                                                                                id: item.id,
                                                                                                tag: item.theme && item.theme.value == 'proptree' ? item.theme.value : null
                                                                                            }
                                                                                        })
                                                                                        .filter((item) => item.tag !== 'proptree')}
                                                                                    name="feed-form"
                                                                                    id="feed-form"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {
                                                            !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                {
                                                                    !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                                    <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                        {
                                                                            !(this.state.feed.property && this.state.feed.property.value) &&
                                                                            <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <div className="space-y-6 lg:pb-5">
                                                                            <div>
                                                                                <label htmlFor="feed-ad-type" className="block text-sm font-medium text-gray-900">
                                                                                    Ad
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.state.feed.traffic = option;
                                                                                            delete this.state.feed.qualification;
                                                                                            delete this.state.feed.form;
                                                                                            this.setState({
                                                                                                feed: this.state.feed
                                                                                            })
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.traffic ? this.state.feed.traffic : { id: 0, name: 'Click to Select' }}
                                                                                        options={(this.state.feed.channel && this.state.feed.channel.value == "proptree" ? [
                                                                                            { id: 3, name: 'Proptree', value: "proptree" },
                                                                                        ] : [
                                                                                            { id: 1, name: 'Regular ads', value: "regular_ads" },
                                                                                            { id: 2, name: 'Lead ads', value: 'lead_ads' },
                                                                                        ].filter((item) => {
                                                                                            if (this.renders.basicUI()) {
                                                                                                return item.value == 'lead_ads'
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }))}
                                                                                        name="feed-ad-type"
                                                                                        id="feed-ad-type"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                                    <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                        {
                                                                            !(this.state.feed.traffic && this.state.feed.traffic.value) &&
                                                                            <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <div className="space-y-6 lg:pb-5">
                                                                            <div>
                                                                                <label htmlFor="feed-traffic" className="block text-sm font-medium text-gray-900">
                                                                                    Traffic
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.state.feed.qualification = option;
                                                                                            delete this.state.feed.form;
                                                                                            this.setState({
                                                                                                feed: this.state.feed
                                                                                            })
                                                                                        }}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.qualification ? this.state.feed.qualification : { id: 0, name: 'Click to Select' }}
                                                                                        options={this.state.feed.traffic && this.state.feed.traffic.value === 'lead_ads' ? [
                                                                                            { id: 3, name: 'WhatsApp -> Form', value: 'form_site' },
                                                                                            { id: 4, name: 'Website', value: 'website' }
                                                                                        ].filter((item) => {
                                                                                            if (!this.renders.basicUI()) {
                                                                                                return item.value == 'form_site'
                                                                                            } else if (this.renders.basicUI()) {
                                                                                                return item.value == 'website'
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }) : [
                                                                                            { id: 3, name: 'Unlock -> Form', value: 'form_site' }
                                                                                        ]}
                                                                                        name="feed-traffic"
                                                                                        id="feed-traffic"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.feed.qualification && this.state.feed.qualification.value &&
                                                                    !(this.state.feed.qualification && this.state.feed.qualification.value == "website") &&
                                                                    //!(this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                                    <div className={!(this.state.feed.channel && this.state.feed.channel.value == "proptree") ? "sm:col-span-4 col-span-12 relative" : "col-span-12 relative"}>
                                                                        {
                                                                            !(this.state.feed.qualification && this.state.feed.qualification.value) &&
                                                                            <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                        }
                                                                        <div className="space-y-6 pb-5">
                                                                            <div>
                                                                                <label htmlFor="feed-form" className="block text-sm font-medium text-gray-900">
                                                                                    Form
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.state.feed.form = option;
                                                                                            this.setState({
                                                                                                feed: this.state.feed
                                                                                            })
                                                                                        }}
                                                                                        showTag={true}
                                                                                        loader={this.state.loading_forms}
                                                                                        disabled={this.state.feed.id}
                                                                                        selected={this.state.feed.form ? this.state.feed.form : { id: 0, name: 'Select form', value: "none" }}
                                                                                        options={this.state.forms
                                                                                            .map((item) => {
                                                                                                return {
                                                                                                    name: item.name,
                                                                                                    id: item.id,
                                                                                                    tag: item.theme && item.theme.value == 'proptree' ? item.theme.value : null
                                                                                                }
                                                                                            })
                                                                                            .filter((item) => item.tag !== 'proptree')}
                                                                                        name="feed-form"
                                                                                        id="feed-form"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className="px-6 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                                {
                                                    !(this.state.feed.id && this.state.feed.channel && this.state.feed.channel.value == "proptree") &&
                                                    !(this.state.feed.id && this.state.feed.channel && this.renders.basicUI()) &&
                                                    !this.state.dontShowSubmitButton &&
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.feed.id) {
                                                                this.functions.updateFeed();
                                                            } else {
                                                                if (this.renders.valid()) {
                                                                    this.functions.createFeed();
                                                                }
                                                            }
                                                        }}
                                                        className={(this.renders.valid() ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        {
                                                            this.state.loading &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.feed.id &&
                                                            <span>Create</span>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            <span>Update</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateNewFeed;
